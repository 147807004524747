/********************************************************************************* RANKING */

/*  index

    ////// RANKING NUMBERS
    ////// RANKING TOP
    ////// RANKING BOTTOM
*/


/*
    ////// RANKING NUMBERS
*/

.ranking-bottom, .ranking-top{
    .book-list{
        .book-item {
            &:after{
                content: attr(data-index-number);
            }
        }
    }
}

/*
    ////// RANKING TOP
*/
.ranking-top{
    padding: 100px 0;
    min-height: 700px;

    .book-list-wrapper{
        max-width: $wrapper;
        margin: 0 auto;
        height: 0;
        visibility: hidden;
        opacity: 0;
        animation: displayIn;
        animation-duration: 0.4s;
        animation-timing-function: ease-out;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
    }

    .book-list{
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 150px;

        .book-item{
            position: relative;

            &:after{
                position: absolute;
                top: -30px;
                left: -20px;
                color: white;
                width: 65px;
                height: 65px;
                text-align: center;
                @include font-size(3);
                font-weight: $bold;
                border-radius: 100px;
                border: 5px solid white;
                box-sizing: border-box;

                @include bp(mobile){
                    @include font-size(2);
                    width: 50px;
                    height: 50px;
                }
            }
        }

        @include bp(desktop){
            grid-column-gap: 70px;
        }
        @include bp(tablet){
            grid-template-columns: 1fr;
            grid-row-gap: 60px;
        }
    }
    @include bp(tablet){
        min-height: 1px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

/*
    ////// RANKING BOTTOM
*/
.ranking-bottom{
    padding: 100px 0;
    max-width: $wrapper;
    margin: 0 auto;

    @extend .book-list-view;

    .book-list{
        .book-item{
            @include flex-container;
            position: relative;

            &:after{
                position: absolute;
                top: -30px;
                left: -20px;
                color: white;
                width: 65px;
                height: 65px;
                text-align: center;
                @include font-size(3);
                font-weight: $bold;
                border-radius: 100px;
                border: 5px solid white;
                box-sizing: border-box;

                @include bp(mobile){
                    @include font-size(2);
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }
    .book-totalpages{
        display: none;
    }
    @include bp(tablet){
        padding-left: 20px;
        padding-right: 20px;
    }
}