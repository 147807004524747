/*
    ////// CONDICIONES USO, POLITICA PRIVACIDAD, POLITICA COOKIES
*/

.text{
  padding: 30px 0;

  p{
    @include font-size(1.4);
    color: $color-medium-gray;
  }
  
}