/********************************************************************************* NEWS */

/*  index

    ////// NEWS COMMONS
    ////// NEWS FEATURED
*/


/*
    ////// NEWS COMMONS
*/
.news-list{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 80px;
    padding-bottom: 80px;

    li{
        margin: 0;
    }

    .news-date{
        @include font-size(1.4);
        color: $color-medium-gray;
    }

    .news-content{
        @include flex-container;

        .news-files{
            @include flex-fixed-element(40%);
            max-width: 240px;

            .news-image{
                height: 240px;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                @include bp(mobile){
                    height: 120px;
                }
            }
            @include bp(mobile){
                @include flex-fixed-element(120px);
            }
        }

        .news-text{
            @include flex-fixed-element(60%);
            padding-left: 50px;

            @include bp(mobile){
                @include flex-flexible-element;
                padding-left: 20px;
            }
        }
    }
    h2{
        @include font-size(1.8);
        font-weight: $bold;
        margin: 0 0 10px 0;
    }
    .news-summary{
        p{
            @include font-size(1.4);
            color: $color-medium-gray;
        }
    }
    .read-more{
        @include font-size(1.4);
        cursor: pointer;
    }
    .news-list_item{
        .news-summary{
            opacity: 0;
            visibility: hidden;

            animation: fadeIn;
            animation-duration: 0.5s;
            animation-timing-function: ease-out;
            animation-delay: 0.5s;
            animation-fill-mode: forwards;

            .long-text{
                display: none;
                height: 0;
            }
        }
        @include bp(mobile){
            .news-summary{
                display: none;
            }
            h2{
                @include font-size(1.4);
                margin: 0;
            }
            .news-date{
                @include font-size(1.2);
            }
        }
    }
    .news-cta{
        display: none;
    }
    @include bp(mobile){
        grid-template-columns: 1fr;
        grid-row-gap: 30px;
        grid-column-gap: 0;
    }
}


/*
    ////// NEWS FEATURED
*/
.featured-news{
    padding: 0 0 60px 0;
    grid-column: 1 / span 2;

    h2{
        @include font-size(3);
        font-weight: $bold;

        @include bp(mobile){
            @include font-size(2.6);
        }
    }
    .news-features{
        padding: 0 0 10px 0;
        margin: 0 0 30px 0;
        @include flex-container;
        height: auto;
    }
    .news-date{
        font-weight: $bold;
        color: $color-main;
    }
    .news-content{
        @include flex-container;

        .news-files{
            @include flex-fixed-element(30%);
            max-width: 380px;

            .news-image{
                height: 380px;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                @include bp(mobile){
                    height: auto;
                }
            }

            @include bp(mobile){
                @include flex-fixed-element(100%);
                max-width: 100%;
                margin: 0 0 20px 0;
            }
        }
        .news-text{
            @include flex-fixed-element(70%);
            padding-left: 50px;

            @include bp(mobile){
                @include flex-fixed-element(100%);
                padding-left: 0;
            }
        }
        .educational-collection-link{
            color: $color-medium-gray;
            text-transform: uppercase;
            @include font-size(1.4);
        }
    }
    .news-summary{
        opacity: 0;
        visibility: hidden;

        animation: fadeIn;
        animation-duration: 0.5s;
        animation-timing-function: ease-out;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;

        .long-text{
            display: none;
            height: 0;
        }

        p{
            @include font-size(1.6);
        }
    }
    @include bp(mobile){
        grid-column: 1;
    }
}