/********************************************************************************* LIBRARIES */

/*  index

    ////// LIBRARIES
    ////// LIBRARY ITEM
    ////// LIBRARIES LIST PAGE
    ////// THEMATIC LIBRARY
*/


/*
    ////// LIBRARIES
*/
.libraries{
    padding: 80px 0;

    /*h2{
        @include font-size(2.3);
        line-height: 27px;
        line-height: 2.7rem;
        text-transform: uppercase;
        font-weight: $bold;

        @include bp(mobile){
            @include font-size(2);
        }
    }*/
    .goto-libraries{
        margin: 60px 0 0 0;
        text-align: center;

        @include bp(mobile){
            margin: 40px 0 0 0;
        }
    }
}

/*
    ////// LIBRARY ITEM
*/
.libraries-list{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;
}
.library-item{
    background: white;
    border-radius: 8px;
    @extend %main-transition;

    a{
        display: block;
    }

    &:hover{
        opacity: 0.5;
    }
}
.library-books{
    margin: 0 0 10px 0;

    .library-book-group{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
                "book1 book2"
                "book3 book4";
        grid-gap: 0;
        background: lighten($color-medium-gray,40%);
    }

    .book-thumbnail{
        height: 140px;
        overflow: hidden;
        margin: 0;
        border-radius: 0;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }

        &:nth-child(1){
            grid-area: book1;
        }
        &:nth-child(2){
            grid-area: book2;
        }
        &:nth-child(3){
            grid-area: book3;
        }
        &:nth-child(4){
            grid-area: book4;
        }
    }
}
.library-thumbnail{
    margin: 0 0 10px 0;
    a{
        display: block;
        height: 325px;

        img{
            max-height: 100%;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: 50% bottom;
        }

        @include bp(tablet){
            height: 270px;
        }
        @include bp(mobile){
            height: 215px;
        }
    }
}
.library-summary{
    p{
        padding: 0 15px;
    }
    p{
        @include font-size(1.2);
        font-weight: $medium;
        color: $color-medium-gray;
    }
    .library-title{
        @include font-size(1.5);
        font-weight: $bold;
        text-transform: uppercase;
        min-height: 25px;
        max-height: 40px;
        overflow-y: hidden;
        color: $color-main;
    }
    .library-educational-collection{
        @include font-size(1.2);
        text-transform: uppercase;
        padding-top: 10px;
        //padding-bottom: 10px;
        font-weight: $light;
    }
}

/*
    ////// LIBRARIES LIST PAGE
*/
.libraries-page{

    .page-header{
        .style-select{
            display: inline-block;
            vertical-align: middle;
            float: right;
        }
        h1{
            display: inline-block;
            vertical-align: middle;

            @include bp(tablet){
                display: block;
                vertical-align: top;
            }
        }

        h2 {
            @include font-size(1.6);
            margin: 0 0 10px 0;
            font-weight: $normal;
        }

    }
    .libraries-list{
        @include bp(tablet){
            grid-template-columns: repeat(2, 1fr);
        }
        @include bp(mobile){
            grid-template-columns: 1fr;
        }
    }
}
.trigger-libraries-list{
    padding: 20px 60px 20px 20px;
    @include font-size(1.5);
    text-transform: uppercase;
    font-weight: $bold;
    display: block;

    &:hover{
        cursor: pointer;
    }
    @include bp(tablet){
        @include font-size(1.2);
    }
}
.libraries-list-selection{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    float: right;

    .libraries-dropdown-list{
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: white;
        z-index: 1000;
    }
    li{
        @include font-size(1.5);
        text-transform: uppercase;
        font-weight: $bold;
        margin: 0;

        a{
            display: block;
            padding: 20px;
        }
    }
    @include bp(tablet){
        display: block;
        vertical-align: top;
        float: none;
        margin: 20px 0;

        li{
            @include font-size(1.2);
        }
    }
}

/*
    ////// THEMATIC LIBRARY
*/
.thematiclibrary-page{
    .page-header{
        .library-share{
            display: inline-block;
            vertical-align: middle;
            float: right;
            margin: -90px 0 0 0;

            .social-share{
                padding: 0;
                background: none;
            }
            a{
                padding: 10px 25px;
            }
            @include bp(tablet){
                display: block;
                float: none;
                margin: 10px 0 0 0;
            }
        }
        h1{
            display: inline-block;
            vertical-align: middle;
        }

        h2 {
            @include font-size(1.6);
            margin: 0 0 10px 0;
            font-weight: $normal;
        }
    }
}
.library-book-list{
    padding: 60px 0;

    .book-list{
        @include bp(tablet){
            grid-template-columns: repeat(3, 1fr);
        }
        @include bp(mobile){
            grid-template-columns: repeat(2, 1fr);
        }
    }
}