/********************************************************************************* BUTTONS */

/*  index

    ////// MAIN STYLES
    ////// BUTTONS
*/

/*
    ////// MAIN STYLES
*/

.button, input[type="submit"]{
    display: inline-block;
    @extend %main-transition;
    width: auto;
    text-decoration: none;
    border: none;
    padding: 20px 60px;
    @include font-size(1.6);
    font-weight: $bold;
    text-align: center;
    border-radius: 3px;

    &.disabled{
        opacity: 0.4;
        pointer-events: none;
    }

    @include bp(mobile){
        padding: 20px 50px;
        @include font-size(1.4);
    }
}

/*
    ////// BUTTONS
*/
.button_primary{
    @extend .button;
    background: $color-secondary;
    @include color-link(white);
    text-transform: uppercase;

    &:hover, &:active{
        background: darken($color-secondary,15%);
    }
}
.button_secondary{
    @extend .button;
    background: $color-main;
    @include color-link(white);

    &:hover, &:active{
        background: darken($color-main,25%);
    }
}
.button_outline{
    @extend .button;
    background: transparent;
    text-transform: uppercase;
    border: 1px solid $color-main;
    @include color-link($color-main);

    &:hover, &:active{
        background: lighten($color-main,80%);
    }
}
.button_download{
    @extend .button;
    background: url("../img/commons/icon-download.svg") no-repeat left 30px center $color-main;
    @include color-link(white);
    padding: 15px 40px 15px 80px;
    @include font-size(1.4);
    text-transform: uppercase;

    @include bp(mobile){
        padding: 15px 40px 15px 45px;
        background: url("../img/commons/icon-download.svg") no-repeat left 20px center $color-main;
    }
}

.button_plus{
    @extend .button;
    background: url("../img/commons/icon-plus.svg") no-repeat left 30px center $color-main;
    @include color-link(white);
    padding: 15px 40px 15px 50px;
    @include font-size(1.4);
    text-transform: uppercase;
    text-align: left;

    @include bp(mobile){
        padding: 15px 40px 15px 45px;
        background: url("../img/commons/icon-plus.svg") no-repeat left 20px center $color-main;
    }
}