/********************************************************************************* FORMS */

/*  index

    ////// COMMON ELEMENTS
    ////// MAIN FORM
*/

/*
    ////// COMMON ELEMENTS
*/

input[type="text"], input[type="email"], input[type="password"], input[type="tel"], input[type="search"], input[type="number"] {
	padding: 10px 20px;
	box-sizing: border-box;
    border: 1px solid $color-main;
	border-radius: $border-radius;
	@include font-size(1.6);
    display: block;
    width: 100%;
}
input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus, input[type="tel"]:focus, input[type="search"]:focus, input[type="number"]:focus{
    border: 1px solid $color-main;
    background: lighten($color-main,85%);
}
label{
    display: block;
}
input[type="checkbox"], input[type="radio"]{
	vertical-align: middle;	
	display: inline-block;
	float: left;
	margin: 3px 6px 0 0;
    
    & + label {
        display: inline-block;
        cursor: pointer;
        max-width: calc(100% - 20px);
    }
}
//input[type="submit"]{
    //@include main-button($color-main-button, $color-main-button-text, $color-main-button-text-hover);
//}
textarea {
	padding: 10px 20px;
	box-sizing: border-box;
	min-height: 100px;
    border: 1px solid $color-main;
	border-radius: 3px;
	@include font-size(1.6);
	width: 100%;
    
    &:focus{
        border: 1px solid $color-secondary;
        background: lighten($color-secondary,47%);
    }
}
/*Sytle SELECT*/
    .style-select {
        background: white;
        background: none\0;
        width: 100%;
        border: 1px solid darken($color-main,10%);
        border: none\0;
        border-radius: 3px;
        -webkit-appearance: none;
        -moz-appearance: none;
        /*-moz-appearance:window;*/

        select {
            background: transparent;
            background: white\0;
            -webkit-appearance: none;
            -moz-appearance: none;
            /*-moz-appearance:window;*/
            width: 100%!important;
            padding: 10px;
            border: none;
            border: 1px solid $color-main \9;

            &:hover{
                cursor: pointer;
            }
        }
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
       /* IE10+ specific styles go here */  
       .style-select {
        background: none;
        border: none;

        select {
            background: white;
        }
       }
    }
/*end Sytle SELECT*/

fieldset{
    border: none;
    //margin: 0 0 10px 0;
    padding: 0;
    
    &.error{
        border: 1px dotted $color-red;
        padding: 5px;
        border-radius: 5px;
    }
    
    p.error{
        text-align: left;
        color: $color-red;
        @include font-size(1.2);
        font-weight: $bold;
        margin: 5px 0 0 0;
    }
}
.error-message{
    padding-bottom: 0px !important;

    .error{
        text-align: left;
        color: $color-red;
        @include font-size(1.2);
        font-weight: $bold;
        margin: 5px 0 0 0;
    }
}

.checkbox-group{
    text-align: left;
    margin: 5px 0;

    input[type="checkbox"]{
        display: inline-block;
        width: 20px;
        float: none;
        margin: -5px 0 0 0;

        & + label{
            display: inline-block;
            width: calc(100% - 30px);
        }
    }
}
input{
    ::-webkit-input-placeholder {
        @include font-size(1.6);
    }

    :-moz-placeholder { /* Firefox 18- */ 
        @include font-size(1.6);
    }

    ::-moz-placeholder {  /* Firefox 19+ */ 
        @include font-size(1.6);
    }

    :-ms-input-placeholder {  
        @include font-size(1.6);
    }
}



/*
    ////// MAIN FORM
*/
.main-form{
    fieldset{
        margin: 0 0 30px 0;
    }
    label{
        @include font-size(1.5);
        font-weight: $bold;
        text-transform: uppercase;
        margin: 0 0 10px 0;

        @include bp(mobile){
            @include font-size(1.2);
        }
    }
    .checkbox-group{
        input[type="checkbox"]{
            display: none;

            & + label{
                padding: 5px 0 5px 35px;
                @extend %main-transition;
            }
        }
    }
    .radiobutton-group{
        input[type="radio"]{
            display: none;

            & + label{
                padding: 5px 0 5px 30px;
                @extend %main-transition;
            }
        }
    }
}