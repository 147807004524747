/********************************************************************************* HOME */

/*  index

    ////// SLIDER
    ////// HOME COLLECTIONS
    ////// NEW BOOKS
    ////// FEATURED AUTHOR

*/

/*
    ////// SLIDER
*/
.owl-carousel{
    &.main-slide{
        .owl-nav{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: 40px;

            @include bp(tablet){
                height: 20px;
            }
        }
        .owl-prev, .owl-next{
            i{
                @include font-size(3.4);
                color: white;
            }
        }
        .owl-prev{
            position: absolute;
            left: 40px;

            @include bp(tablet){
                left: 20px;
            }
        }
        .owl-next{
            position: absolute;
            right: 40px;

            @include bp(tablet){
                right: 20px;
            }
        }
        .goleft{
            //background: url("../img/commons/arrow-slide-left.svg") no-repeat center center;
            width: 30px;
            height: 45px;
            display: block;

            @include bp(tablet){
                //background-size: 10px;
                //width: 20px;
                //height: 40px;
            }
        }
        .goright{
            //background: url("../img/commons/arrow-slide-right.svg") no-repeat center center;
            width: 30px;
            height: 45px;
            display: block;

            @include bp(tablet){
                //background-size: 10px;
                //width: 20px;
                //height: 40px;
            }
        }
        .owl-dots{
            position: absolute;
            width: 100%;
            text-align: center;
            bottom: 10px;

            .owl-dot{
                background: none;
                border: 2px solid white;
                width: 14px;
                height: 14px;
                opacity: 0.5;
                border-radius: 100px;
                margin: 0 2px;

                &.active{
                    opacity: 1;
                    background: white;
                }
                &:hover{
                    opacity: 1;
                    background: white;
                }
            }
            @include bp(tablet){
                bottom: 5px;

                .owl-dot{
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
    .main-slider-image{
        //max-height: 560px;

        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}


/*
    ////// HOME COLLECTIONS
*/
.home-educational-collections{
    background: white;
    padding: 10px;
    margin: 20px 0;
}

/*
    ////// NEW BOOKS
*/
.home-newbooks{
    padding: 80px 0;

    @include bp(mobile){
        padding: 50px 0;
    }
}



/*
    ////// FEATURED AUTHOR
*/
.home-featured-author{
    background: white;
    padding: 70px 0;

    @include bp(mobile){
        padding: 40px 0;
    }
}

