/********************************************************************************* AUTHORS */

/*  index

    ////// FEATURED AUTHOR
    ////// AUTHOR a to z LIST
    ////// AUHTOR DETAIL
*/

/*
    ////// FEATURED AUTHOR
*/
.featured-author{
    @include flex-container;
    height: auto;
    justify-content: center;
    align-items: center;
    text-align: center;

    .featured-author-photo{
        width: 160px;
        height: 160px;
        border-radius: 100px;
        margin: 0 auto 10px auto;
        overflow: hidden;
        position: relative;

        a{
            display: block;
        }
        img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
    .featured-author-summary{
        @include flex-fixed-element(50%);
        padding: 20px 0;
        max-width: 400px;

        @include bp(tablet){
            @include flex-fixed-element(100%);
            max-width: none;
        }
    }
    .featured-author-books{
        @include flex-fixed-element(50%);
        padding: 0 0 0 20px;

        h3{
            @include font-size(1.8);
            color: $color-medium-gray;
            text-transform: uppercase;
            font-weight: $bold;
            margin: 0 0 30px 0;
        }

        ul{
            text-align: right;

            @include bp(tablet){
                text-align: center;
            }
        }

        li{
            display: inline-block;
            vertical-align: middle;
            position: relative;

            img{
                max-height: 300px;

                @include bp(mobile){
                    max-height: none;
                    max-width: 35vw;
                }
            }

            &:nth-child(1){
                z-index: 100;

                img{
                    transform: scale(1.1);
                    box-shadow: 6px 0 10px rgba($color-main,0.5);
                }
            }
        }

        @include bp(tablet){
            @include flex-fixed-element(100%);
            padding: 0;
        }
    }
    h2{
        @include font-size(3);
        line-height: 35px;
        line-height: 3.5rem;
        font-weight: $medium;
        text-transform: uppercase;
        margin: 0 0 5px 0;

        a{
            @include color-link($color-main);
        }
    }
    p{
        line-height: 32px;
        line-height: 3.2rem;
    }
    .featured-author-label{
        color: $color-medium-gray;
        text-transform: uppercase;
        font-weight: $light;
    }
}


/*
    ////// AUTHOR a to z LIST
*/
.atoz-menu{
    padding: 20px 0 0 0;

    @include bp(tablet){
        @include flex-container;
        align-items: center;
        position: relative;
    }
}
#atoz-nav{
    .ln-letters{
        @include flex-container;

        a{
            border: none;
            float: none;
            padding: 10px;
            @include font-size(1.8);
            font-weight: $bold;
            text-transform: uppercase;

            &.ln-disabled{
                opacity: 0.5;
            }
            @media screen and (max-width: 1000px){
                padding: 10px 7px;
            }
        }
        .all{
            opacity: 0;
            visibility: hidden;
            animation: fadeIn;
            animation-duration: 0.5s;
            animation-timing-function: ease-out;
            animation-delay: 0.5s;
            animation-fill-mode: forwards;
        }
    }
    @include bp(tablet){
        @include flex-fixed-element(70%);
        order: 1;
    }
}
.trigger-letters{
    border-radius: 4px;
    padding: 5px 35px 5px 5px;
    @include font-size(1.4);
}
.group-letters{
    @include bp(tablet){
        @include flex-fixed-element(30%);
        order: 2;
        text-align: right;
    }
    .grouped-letters{
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: white;
        border: 1px solid $color-light-gray;
        z-index: 1000;
        text-align: center;

        a{
            display: block;
            padding: 5px 10px;
            border-bottom: 1px solid $color-light-gray;
            @include font-size(1.4);
        }
    }
}
.author-list{
    padding: 60px 0;

    a{
        display: block;
    }

    .author-image{
        overflow: hidden;
        margin: 0 0 20px 0;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;

            visibility: hidden;
            opacity: 0;
            animation: fadeIn;
            animation-duration: 0.4s;
            animation-timing-function: ease-out;
            animation-delay: 0.5s;
            animation-fill-mode: forwards;
        }
    }
    .author-name{
        @include font-size(1.5);
        text-transform: uppercase;
        font-weight: $bold;
    }
    #atoz{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 50px;

        li{
            margin: 0;

            &.ln-no-match{
                grid-column: 1 / span 5;
                text-align: center;
                color: $color-red;

                @include bp(tablet){
                    grid-column: 1 / span 4;
                }
                @include bp(mobile){
                    grid-column: 1 / span 2;
                }
            }
        }

        @include bp(tablet){
            grid-template-columns: repeat(4, 1fr);
        }
        @include bp(mobile){
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
.group-letters{
    display: none;

    .grouped-letters{
        display: none;
    }

    @include bp(tablet){
        display: block;
    }
}


/*
    ////// AUTHOR DETAIL
*/
.author-detail{
    display: grid;
    grid-template-columns: 1.5fr 3.5fr;
    grid-gap: 50px;
    padding: 60px 0 80px 0;

    h1{
        @include font-size(3.6);
        font-weight: $bold;
        text-transform: uppercase;
        margin: 0 0 30px 0;
        padding: 0 0 30px 0;
    }
    h2{
        @include font-size(1.8);
        font-weight: $bold;
    }
    .author-image{
        img{
            max-width: 100%;
        }

        @include bp(tablet){
            text-align: center;
        }
    }
    .author-social-list{
        margin: 60px 0;
        padding: 10px;

        .author-instagram, .author-twitter, .author-website{
            a{
                @extend .button_outline;
                @include font-size(1.4);
                padding: 15px 60px;
                display: block;
                width: calc(100% - 20px);
                margin: 0 10px;

                @include bp(tablet){
                    padding: 15px 15px 15px 80px;
                    text-align: left;
                }
            }
        }
    }
    .author-book-list, .author-bibliography{
        margin: 60px 0 0 0;
        padding: 40px 0 0 0;
    }
    .author-bibliography{
        li{
            @include font-size(1.4);
            margin: 0;
        }
        a{
            padding: 10px 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 30px;

            @include bp(tablet){
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 10px;
                grid-template-areas:    "title date"
                                        "title editorial";
            }
        }
        .author-bibliography-title{
            font-weight: $bold;

            @include bp(tablet){
                grid-area: title;
            }
        }
        .author-bibliography-publication-date{
            @include bp(tablet){
                grid-area: date;
            }
        }
        .author-bibliography-editorial{
            text-align: right;

            @include bp(tablet){
                text-align: left;
                grid-area: editorial;
            }
        }
    }

    .author-preview{
        h1{
            margin: 0 0 20px 0;
            padding: 0;
            @include font-size(3);
            text-align: center;
        }
    }

    .book-thumbnail{
        border-radius: 0;
        margin: 0;

        a{
            height: 210px;
        }
    }
    .show-more-author-books{
        border: none;
        text-align: center;
        padding: 5px 25px 5px 5px;
        margin: 0 auto;
        display: block;

        @include bp(tablet){
            display: none;
        }
    }

    @media screen and (min-width: 901px){
        .author-book-list{
            .book-item{
                &:not(.visible){
                    display: none;
                }
            }
        }
    }

    @include bp(tablet){
        padding: 30px 0 80px 0;
        display: block;
        grid-template-columns: 1fr;
        /*grid-gap: 0;
        grid-row-gap: 30px;*/
    }
}