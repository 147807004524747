/********************************************************************************* PAGER */

/*  index

    ////// COMMON STYLES
*/


/*
    ////// COMMON STYLES
*/
.pager{
    @include flex-container;
    height: auto;

    li{
        margin: 0;
        @include font-size(1.6);
        font-weight: $bold;
    }
    .previous, .next{
        a{
            display: block;
            width: 30px;
            height: 30px;
        }
    }
    .page-list{
        text-align: center;

        span{
            padding: 0 10px;
        }
    }

    .bottom-pager &{
        grid-area: pager;
    }
}

.bottom-pager{
    padding: 20px 0;

    .center-wrapper{
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        grid-gap: 32px;
        grid-template-areas: "blank blank pager";

        @include bp(tablet){
            grid-template-columns: 1fr;
            grid-template-areas: "pager";
        }
    }
}