/********************************************************************************* FAQS */

/*  index

    ////// FAQS
*/


/*
    ////// FAQS
*/
.faqs-list{
    li{
        margin: 0 0 30px 0;
    }
}
.faq-title{
    margin: 0;
    padding: 20px 30px;
    @include font-size(1.8);
    font-weight: $bold;

    &:hover{
        cursor: pointer;
    }
}
.faq-content{
    display: none;
    padding: 30px;

    p{
        @include font-size(1.4);
        color: $color-medium-gray;
    }
}