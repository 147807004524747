/********************************************************************************* COMMONS */

/*  index

    ////// GLOBALS
    ////// FEEDBACK STYLES
    ////// SOCIAL LINKS
    ////// BREADCRUMB
    ////// OWL CAROUSEL & FEATHER LIGHT
    ////// PAGES ELEMENTS
*/



/*
    ////// GLOBAL SYTLES
*/
html{

    body{
        &:not(.register-page){
            scroll-behavior: smooth;
        }
    }
}
body {
    @extend %font-family-main;
	@include font-size(1.6);
	color: $color-main;
    font-weight: $normal;

    &.frozen{
        overflow: hidden;
    }
}
ul{
	li{
		list-style-type: none;
	}
}
ol{
	li{
		list-style-type: decimal;
		list-style-position: inside;
	}
}
a{
	cursor: pointer;
    
    &:link{ color: $color-main;}
    &:hover{} 
    &:visited{color: $color-main;}
    &:active{color: $color-main;}
}
img{
    max-width: 100%;
}

/*
    ////// FEEDBACK STYLES
*/
.feedback{
    margin: 10px 0;
    text-align: left;

    &.success{
        //background: url("../img/icon-tick.svg") no-repeat left 5px;
        //padding: 5px 5px 5px 30px;
        color: $color-green;
        font-weight: $bold;
    }
    &.error{
        //background: url("../img/icon-alert.svg") no-repeat left 5px;
        //padding: 5px 5px 5px 30px;
        color: $color-red;
        font-weight: $bold;
        @include font-size(1.4);
    }
    p{
        @include font-size(1.4);
    }
}
.flashmessage{
    margin: 10px 0;

    &.success{
        background: url("../img/commons/icon-tick.svg") no-repeat 10px center;
        padding: 10px 10px 10px 50px;
        color: $color-green;
        font-weight: $bold;
        border: 1px solid $color-green;
    }
    &.error{
        background: url("../img/commons/icon-alert.svg") no-repeat 10px center;
        padding: 10px 10px 10px 50px;
        color: $color-red;
        font-weight: $bold;
        border: 1px solid $color-red;
    }
    p{
        @include font-size(1.4);
        margin: 0;
    }
}

/*
    ////// SOCIAL LINKS
*/
.social-links{
    @include flex-container;
    justify-content: flex-start;
    height: auto;

    li{
        margin: 0 30px 0 0;
    }
    a{
        width: 20px;
        height: 20px;
        display: block;
        @extend %main-transition;
    }
    .facebook{
        a{
            background: url("../img/commons/social-icon-facebook.svg") no-repeat center center;
        }
    }
    .twitter{
        a{
            background: url("../img/commons/social-icon-twitter.svg") no-repeat center center;
        }
    }
    .instagram{
        a{
            background: url("../img/commons/social-icon-instagram.svg") no-repeat center center;
        }
    }
}
.social-share{
    @include flex-container;
    justify-content: space-around;
    background: white;
    padding: 12px 30px;

    a{
        width: 30px;
        height: 30px;
        display: inline-block;

        &:hover{
            opacity: 0.3;
        }
    }
}

/*
    ////// BREADCRUMB
*/
.breadcrumb{
    @include font-size(1.2);
    font-weight: $medium;
    margin: 0 0 30px 0;

    .breadcrumb-separator{
        padding: 5px 0;
    }
    .current-page{
        padding: 5px;
    }

    a{
        display: inline-block;
        padding: 5px;

        &:hover, &:active{
            text-decoration: underline;
        }
    }
}


/*
    ////// OWL CAROUSEL & FEATHER LIGHT
*/
.owl-dots{
    text-align: center;
    padding: 20px 0;
}
.owl-dot{
    margin: 0 7px;

    span{
        width: 14px;
        height: 14px;
        display: block;
        border-radius: 100px;
    }
}
.owl-carousel{
    display: block;
    position: relative;

    .owl-nav{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }
    .owl-prev{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -40px;

        &.disabled{
            opacity: 0.3;
            cursor: default;
        }
    }
    .owl-next{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -40px;

        &.disabled{
            opacity: 0.3;
            cursor: default;
        }
    }

    @include bp(tablet){
        .owl-prev{
            left: -10px;
        }
        .owl-next{
            right: -10px;
        }
    }
}
.slide-left, .slide-right{
    width: 40px;
    height: 40px;
    display: block;
}
.featherlight-content{
    .featherlight-previous, .featherlight-next{
        & > span{
            display: none;
        }
        &:hover > span{
            display: none;
        }
    }
}


/*
    ////// PAGES ELEMENTS
*/
.page-header{
    padding: 20px 0;

    h1{
        text-transform: uppercase;
        @include font-size(3.6);
        font-weight: $bold;
        margin: 0;
    }
    h2, .ancestor{
        text-transform: uppercase;
        @include font-size(2.3);
        font-weight: $bold;
        margin: 0;

        @include bp(tablet){
            text-align: center;
        }

        &.not-uppercase {
            text-transform: none;
        }
    }
    @include bp(tablet){
        text-align: center;

        h1{
            @include font-size(3);
            line-height: 35px;
            line-height: 3.5rem;
        }
        h2{
            @include font-size(2);
        }
    }
}
.section-title{
    @include font-size(2.3);
    line-height: 27px;
    line-height: 2.7rem;
    text-transform: uppercase;
    font-weight: $bold;

    @include bp(mobile){
        @include font-size(2);
    }
}

/*
    ////// Cookies
*/
.cookies-alert{
    position: fixed;
    bottom: 0;
    width: 100%;
    background: $color-medium-gray;
    color: white;
    padding: 10px;
    z-index: 100000;

    .cookies-alert-content{
        max-width: $wrapper;
        margin: 0 auto;
    }

    p{
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        @include font-size(1.4);

        a{
            @include color-link(white);
            text-decoration: underline;

            &:hover{
                color: $color-main;
            }
        }
    }
    .close-cookies-alert{
        display: inline-block;
        vertical-align: middle;
        float: right;
        background: $color-secondary;
        color: white;
        @include font-size(1.4);
        border: none;
        border-radius:4px;
        padding: 5px 10px;

        &:hover{
            background: darken($color-secondary,10%);
        }
    }
}

