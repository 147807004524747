/********************************************************************************* REGISTER */

/*  index

    ////// FORM
    ////// REGISTER VALIDATION
*/


/*
    ////// FORM
*/
.register-form{
    padding: 60px 0;

    fieldset{
        margin: 0;
    }

    .main-form{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: minmax(0, 1fr);
        grid-gap: 50px;
        grid-template-areas:
            "name surname"
            "professionalprofile professionalprofile"
            "email institucion_educativa"
            "country ciudad_institucion_educativa"
            "password codigo_postal_institucion_educativa"
            "newslettersubjects newslettersubjects"
            "newslettersubscription newslettersubscription"
            "rgpd rgpd"
            "legals legals"
            "submit .";

        input[type="checkbox"], input[type="radio"]{
            & + label{
                @include font-size(1.4);
                text-transform: none;
                color: $color-main;
                font-weight: $normal;
                margin: 0;
            }
        }
        @include bp(mobile){
            grid-template-columns: 1fr;
            grid-template-areas:
                    "name"
                    "surname"
                    "professionalprofile"
                    "email"
                    "country"
                    "password"
                    "institucion_educativa"
                    "ciudad_institucion_educativa"
                    "codigo_postal_institucion_educativa"
                    "newslettersubjects"
                    "newslettersubscription"
                    "rgpd"
                    "legals"
                    "submit";
            grid-gap: 30px;
        }
    }
    &.latam{
        .main-form{
            grid-template-areas:
                "name surname"
                "professionalprofile professionalprofile"
                "email institucion_educativa"
                "country ciudad_institucion_educativa"
                "password codigo_postal_institucion_educativa"
                "newslettersubjects newslettersubjects"
                "newslettersubscription newslettersubscription"
                "legals legals"
                "submit .";

            @include bp(mobile){
                grid-template-areas:
                    "name"
                    "surname"
                    "professionalprofile"
                    "email"
                    "country"
                    "password"
                    "institucion_educativa"
                    "ciudad_institucion_educativa"
                    "codigo_postal_institucion_educativa"
                    "newslettersubjects"
                    "newslettersubscription"
                    "legals"
                    "submit";
            }
        }
    }
    .hint{
        @include font-size(1.2);
        font-weight: $normal;
        text-transform: none;
        color: $color-medium-gray;
        margin: 15px 0 0 0;
    }
    .register-form_name{
        grid-area: name;
    }
    .register-form_surname{
        grid-area: surname;
    }
    .professional-profile{
        grid-area: professionalprofile;

        p{
            @include font-size(1.5);
            font-weight: $bold;
            text-transform: uppercase;

            @include bp(mobile){
                @include font-size(1.2);
            }
        }
        .professional-profile-options{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 30px;
            padding: 20px 30px;

            @include bp(tablet){
                grid-gap: 20px;
            }
            @include bp(mobile){
                grid-template-columns: repeat(2, 1fr);
                padding: 20px 10px;
            }
        }

        input[type="radio"]{
            opacity: 0;
            position: absolute;
            z-index: 1;

            & + label{
                padding: 20px 40px;
                @include font-size(1.2);
                font-weight: $normal;
                text-transform: uppercase;
                @extend %main-transition;
                width: 100%;
                box-sizing: border-box;
                text-align: center;
                margin: 0;
                position: relative;
                z-index: 10;

                @include bp(tablet){
                    padding: 20px;
                }
            }
        }
    }
    .register-form_email{
        grid-area: email;
    }
    .register-form_country{
        grid-area: country;
    }
    .register-form_institucion_educativa{
        grid-area: institucion_educativa;
    }
    .register-form_ciudad_institucion_educativaa{
        grid-area: ciudad_institucion_educativa;
    }
    .register-form_codigo_postal_institucion_educativa{
        grid-area: codigo_postal_institucion_educativa;
    }
    .register-form_password{
        grid-area: password;

        input{
            &::-webkit-input-placeholder {
                @include font-size(1.4);
                opacity: 0.5;
            }

            &:-moz-placeholder { /* Firefox 18- */
                @include font-size(1.4);
                opacity: 0.5;
            }

            &::-moz-placeholder {  /* Firefox 19+ */
                @include font-size(1.4);
                opacity: 0.5;
            }

            &:-ms-input-placeholder {
                @include font-size(1.4);
                opacity: 0.5;
            }
        }
    }
    .newsletter-subjects{
        grid-area: newslettersubjects;

        p{
            @include font-size(1.5);
            font-weight: $bold;
            text-transform: uppercase;

            @include bp(mobile){
                @include font-size(1.2);
            }
        }
    }
    .newsletter-subject-list{
        @include flex-container;
        height: auto;
        margin: 20px 0 0 0;

        p{
            @include font-size(1.4);
            font-weight: $bold;
            text-transform: uppercase;
        }
        @include bp(mobile){
            li{
                @include flex-fixed-element(48%);
            }
        }
    }
    .register-form_newsletter-subscription{
        grid-area: newslettersubscription;

        p{
            @include font-size(1.5);
            font-weight: $bold;
            text-transform: uppercase;

            @include bp(mobile){
                @include font-size(1.2);
            }
        }
        .legal-note{
            padding: 30px;
            margin: 15px 0;
            border-radius: 3px;

            p{
                @include font-size(1.3);
                font-weight: $normal;
                text-transform: none;
                color: $color-medium-gray;
            }
        }
    }
    .legal-consents{
        grid-area: legals;
    }
    .button_primary{
        grid-area: submit;
    }
    input:invalid{
    }
    .lopd-block{
        grid-area: rgpd;

        br{
            display: none;
        }
        div{
            margin: 0 0 10px 0;
        }
        .rgpd-text-header{
            @include font-size(1.4);
        }
        .rgpd-text-checks{
            @include font-size(1.4);
        }
        a{
            text-decoration: underline;
            font-weight: $bold;
        }
    }
}

/*
    ////// REGISTER VALIDATION
*/
.register-validation{
    text-align: center;
    padding: 0 30px 90px;
    width: 100%;
    max-width: 960px;
    margin: auto;

    &:before{
        display: block;
        content: "";
        width: 300px;
        height: 260px;
        margin: auto;

        background: url("../img/commons/register-bg.jpg") no-repeat center bottom;
        background-size: 100%;
    }

    h1{
        @include font-size(3.6);
        font-weight: $bold;
        text-transform: uppercase;
        margin: 0;
    }
    h2{
        @include font-size(2.3);
        font-weight: $bold;
        text-transform: uppercase;
    }
    p{
        @include font-size(1.8);
        font-weight: $normal;
    }
}