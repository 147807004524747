/********************************************************************************* MIXINS */

/*  index

    ////// SKINS
        ===> PRIMARY COLORS
        ===> SECONDARY COLORS
    ////// COLLECTIONS MENU
*/


/*
    ////// SKINS
*/

/* ===> PRIMARY COLORS*/

$skins: (
        planetalector: $color-planetalector,
        grup62: $color-grup62
);

@each $key, $val in $skins {
    body{
        &.skin-#{$key} {

            .header {
                .login {
                    @include color-link($val);
                }

                .my-account, .signout {
                    a {
                        @include color-link($val);
                    }
                }
            }

            .show-mobile-menu {
                background: url("../img/#{$key}/icon-menu.svg") no-repeat left 20px center;

                @include bp(mobile){
                    background: url("../img/#{$key}/icon-menu.svg") no-repeat left 10px center;
                }
            }

            .trigger-search {
                background: url("../img/#{$key}/icon-search.svg") no-repeat center center;
            }

            .trigger-access, .goto-profile {
                background: url("../img/#{$key}/icon-user.svg") no-repeat center center;
            }

            .close-menu {
                background: url("../img/#{$key}/icon-close.svg") no-repeat right 10px center;
            }

            .social-links {
                .facebook {
                    a {
                        &:hover {
                            background: url("../img/#{$key}/social-icon-facebook.svg") no-repeat center center;
                        }
                    }
                }

                .twitter {
                    a {
                        &:hover {
                            background: url("../img/#{$key}/social-icon-twitter.svg") no-repeat center center;
                        }
                    }
                }

                .instagram {
                    a {
                        &:hover {
                            background: url("../img/#{$key}/social-icon-instagram.svg") no-repeat center center;
                        }
                    }
                }
            }

            .educational-collections-menu {
                .goto-catalog {
                    background: $val;

                    &:hover {
                        background: darken($val, 10%);
                    }
                }
            }

            .region-selected {
                color: $val;
                background: url("../img/#{$key}/icon-world.svg") no-repeat left center;
                padding: 5px 20px 5px 30px;
                position: relative;

                &:after {
                    content: url("../img/#{$key}/arrow-down.svg");
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .owl-carousel {
                &.main-slide {
                    .owl-dots {

                        .owl-dot {
                            border: 2px solid $val;

                            &.active {
                                background: $val;
                            }

                            &:hover {
                                background: $val;
                            }
                        }
                    }
                }
            }

            .button_primary {
                background: $val;

                &:hover, &:active {
                    background: darken($val, 15%);
                }
            }

            .button_outline {
                border: 1px solid $val;
                @include color-link($val);

                &:hover, &:active {
                    background: lighten($val, 80%);
                }
            }

            .recommended-books, .book-detail-gallery, .featherlight-content {
                .slide-left, .featherlight-previous {
                    background: url("../img/#{$key}/arrow-slide-left.svg") no-repeat center center;
                }

                .slide-right, .featherlight-next {
                    background: url("../img/#{$key}/arrow-slide-right.svg") no-repeat center center;
                }
            }

            .owl-dot {
                span {
                    border: 1px solid $val;
                }

                &.active {
                    span {
                        background: $val;
                    }
                }
            }

            .breadcrumb-ancestor {
                @include color-link($val);
                padding: 5px;
            }

            .button_download {
                background: url("../img/commons/icon-download.svg") no-repeat left 30px center $val;

                &:hover, &:active {
                    background: url("../img/commons/icon-download.svg") no-repeat left 30px center darken($val, 10%);
                }

                @include bp(mobile){
                    background: url("../img/commons/icon-download.svg") no-repeat left 20px center $val;

                    &:hover, &:active {
                        background: url("../img/commons/icon-download.svg") no-repeat left 20px center darken($val, 10%);
                    }
                }
            }

            .book-sheet {
                .read-more, .goto-comments {
                    @include color-link($val);
                }
            }

            .book-sheet-tags {
                a {
                    @include color-link($val);
                }
            }

            .main-modal-close {
                background: url("../img/#{$key}/icon-close.svg") no-repeat right center;
            }

            .read-first-chapter {
                background: url("../img/#{$key}/icon-book.svg") no-repeat left 40px center;

                &:hover {
                    background: url("../img/#{$key}/icon-book.svg") no-repeat left 40px center;
                }
            }
            .read-lecturanda {
                background-color: #{$val} !important;
                color: #ffffff !important;
            }
            .digital-book{
                .link-ebook {
                    background: url("../img/#{$key}/icon-e-reader.svg") no-repeat left 20px center;

                    &:hover {
                        background: url("../img/#{$key}/icon-e-reader.svg") no-repeat left 20px center;
                    }

                    @include bp(desktop){
                        background-position: 40px center;

                        &:hover{
                            background-position: 40px center;
                        }
                    }
                }
                .link-ebook-background {
                    background: #76c556 url("../img/#{$key}/icon-e-reader-white.svg") no-repeat left 20px center;
                    border: 1px solid #76c556;
                    color: #ffffff !important;

                    &:hover {
                        background: #76c556 url("../img/#{$key}/icon-e-reader-white.svg") no-repeat left 20px center;
                        opacity: 0.3;
                    }

                    @include bp(desktop){
                        background-position: 40px center;

                        &:hover{
                            background-position: 40px center;
                        }
                    }
                }
                .link-audiolibro {
                    background: url("../img/#{$key}/icon-headphones.svg") no-repeat left 15px center;

                    &:hover {
                        background: url("../img/#{$key}/icon-headphones.svg") no-repeat left 15px center;
                    }

                    @include bp(desktop){
                        background-position: 35px center;

                        &:hover{
                            background-position: 35px center;
                        }
                    }
                }
                .link-audiolibro-background {
                    background: #76c556 url("../img/#{$key}/icon-headphones-white.svg") no-repeat left 15px center;
                    border: 1px solid #76c556;
                    color: #ffffff !important;

                    &:hover {
                        background: #76c556 url("../img/#{$key}/icon-headphones-white.svg") no-repeat left 15px center;
                        opacity: 0.3;
                    }

                    @include bp(desktop){
                        background-position: 35px center;

                        &:hover{
                            background-position: 35px center;
                        }
                    }
                }
                li{
                    &:only-child{
                        .link-ebook, .link-ebook-background {
                            background-position: 40px center;

                            &:hover {
                                background-position: 40px center;
                            }
                        }
                        .link-audiolibro, .link-audiolibro-background {
                            background-position: 40px center;

                            &:hover {
                                background-position: 40px center;
                            }
                        }
                    }
                }
            }


            .trigger-purchase-options {
                background: url("../img/#{$key}/icon-cart.svg") no-repeat left 40px center;

                &:hover {
                    background: url("../img/#{$key}/icon-cart.svg") no-repeat left 40px center;
                }
            }

            .trigger-purchase-options-background {
                background: #76c556 url("../img/#{$key}/icon-cart-white.svg") no-repeat left 40px center;
                border: 1px solid #76c556;
                color: #ffffff !important;

                &:hover {
                    background: #76c556 url("../img/#{$key}/icon-cart-white.svg") no-repeat left 40px center;
                }
            }

            .add-to-favs {
                background: url("../img/#{$key}/icon-fav.svg") no-repeat left 40px center white;
                border: none;

                &:hover {
                    background: url("../img/#{$key}/icon-fav.svg") no-repeat left 40px center white;
                }

                &.active {
                    background: url("../img/#{$key}/icon-fav-selected.svg") no-repeat left 40px center white;
                }
            }

            .mark-as-read {
                background: url("../img/#{$key}/icon-tick.svg") no-repeat left 40px center white;
                border: none;

                &:hover {
                    background: url("../img/#{$key}/icon-tick.svg") no-repeat left 40px center white;
                }

                &.active {
                    background: url("../img/#{$key}/icon-tick-check.svg") no-repeat left 40px center white;
                }
            }

            .social-share{
                .social-whatsapp {
                    background: url("../img/#{$key}/icon-social-whatsapp.svg") no-repeat center center;
                    display: none;

                    @media screen and (max-width: 500px){
                        display: block;
                    }
                }

                .social-twitter {
                    background: url("../img/#{$key}/icon-social-twitter.svg") no-repeat center center;
                }

                .social-facebook {
                    background: url("../img/#{$key}/icon-social-facebook.svg") no-repeat center center;
                }

                .social-envelope {
                    background: url("../img/#{$key}/icon-envelope.svg") no-repeat center center;
                }
            }

            .trigger-author-visit {
                background: url("../img/commons/icon-hand.svg") no-repeat left 40px center $val;

                &:hover {
                    background: url("../img/commons/icon-hand.svg") no-repeat left 40px center darken($val, 10%);
                }
            }

            .trigger-book-sample {
                background: url("../img/commons/icon-document.svg") no-repeat left 40px center $val;

                &:hover {
                    background: url("../img/commons/icon-document.svg") no-repeat left 40px center darken($val, 10%);
                }
            }

            .main-form {
                .checkbox-group {
                    input[type="checkbox"] {
                        & + label {
                            background: url("../img/#{$key}/icon-checkbox.svg") no-repeat left 5px;
                        }

                        &:checked {
                            & + label {
                                background: url("../img/#{$key}/icon-checkbox-checked.svg") no-repeat left 5px;
                            }
                        }
                    }
                }
                .radiobutton-group {
                    input[type="radio"] {
                        & + label {
                            background: url("../img/#{$key}/icon-radiobutton.svg") no-repeat left 5px;
                        }

                        &:checked {
                            & + label {
                                background: url("../img/#{$key}/icon-radiobutton-checked.svg") no-repeat left 5px;
                            }
                        }
                    }
                }
            }

            .header-desktop {
                .user-interaction {
                    .trigger-access {
                        background: url("../img/#{$key}/icon-user.svg") no-repeat center center;
                    }
                    .trigger-search{
                        background: url("../img/#{$key}/icon-search.svg") no-repeat center center;
                        background-size: 20px;
                    }
                }
                .user-access {
                    .login.trigger-access {
                        background: url("../img/#{$key}/icon-user.svg") no-repeat left center;
                    }
                }
            }
            .trigger-view-grid{
                background: url("../img/#{$key}/icon-view-grid.svg") no-repeat center center;

                &.selected{
                    background: url("../img/#{$key}/icon-view-grid-selected.svg") no-repeat center center;
                }
            }
            .trigger-view-list{
                background: url("../img/#{$key}/icon-view-list.svg") no-repeat center center;

                &.selected{
                    background: url("../img/#{$key}/icon-view-list-selected.svg") no-repeat center center;
                }
            }
            .trigger-search-filters{
                background: url("../img/#{$key}/icon-filters.svg") no-repeat center center;

                &.selected{
                    background: url("../img/#{$key}/icon-filters-selected.svg") no-repeat center center;
                }
            }
            .search-filters{
                .second-level{
                    input[type="checkbox"]{
                        &:checked{

                            & + label{
                                color: $val;
                            }
                        }
                    }
                }
            }
            .pager{
                .previous{
                    a{
                        background: url("../img/#{$key}/arrow-pager-left.svg") no-repeat center center;
                    }
                }
                .next{
                    a{
                        background: url("../img/#{$key}/arrow-pager-right.svg") no-repeat center center;
                    }
                }
                .page-list{
                    .current-page{
                        color: $val;
                    }
                }
            }
            .modal-access{
                .forgot-password{
                    a{
                        @include color-link($val);
                    }
                }
            }
            .register-form{
                .professional-profile{
                    input[type="radio"]{
                        & + label{
                            color: $val;
                        }
                        &:checked + label{
                            color: rgba($val,0.3);
                        }
                    }
                }
            }
            #atoz-nav {
                .ln-letters {
                    a {
                        @include color-link($val);
                    }
                }
            }
            .author-detail{
                .author-social-list{
                    .author-instagram {
                        a{
                            background: url("../img/#{$key}/social-icon-instagram.svg") no-repeat left 25px center white;
                            background-size: 30px;

                            &:hover {
                                background: url("../img/#{$key}/social-icon-instagram.svg") no-repeat left 25px center white;
                                background-size: 30px;
                            }
                        }
                    }
                    .author-twitter {
                        a{
                            background: url("../img/#{$key}/social-icon-twitter.svg") no-repeat left 25px center white;
                            background-size: 30px;

                            &:hover {
                                background: url("../img/#{$key}/social-icon-twitter.svg") no-repeat left 25px center white;
                                background-size: 30px;
                            }
                        }
                    }
                    .author-website {
                        a{
                            background: url("../img/#{$key}/social-icon-world.svg") no-repeat left 25px center white;
                            background-size: 30px;

                            &:hover {
                                background: url("../img/#{$key}/social-icon-world.svg") no-repeat left 25px center white;
                                background-size: 30px;
                            }
                        }
                    }
                }
                .show-more-author-books{
                    color: $val;
                    background: url("../img/#{$key}/arrow-down.svg") no-repeat right center;

                    &.active{
                        background: url("../img/#{$key}/arrow-up.svg") no-repeat right center;
                    }
                }
                .libraries-list-selection{
                    .libraries-all{
                        a{
                            @include color-link($val);
                        }
                    }
                }
                .modal-changeregion{
                    .stay-here{
                        @include color-link($val);
                    }
                }
            }
            .user-card{
                .change-photo-form{
                    .change-photo-label{
                        background: url("../img/#{$key}/icon-photo.svg") no-repeat center center;
                    }
                }
                .user-name{
                    .logout{
                        background: url("../img/#{$key}/icon-logout.svg") no-repeat left center;
                        @include color-link($val);
                    }
                }
            }
            .profile-sections {
                .profile-form {
                    .edit-field, .edit-password{
                        background: url("../img/#{$key}/icon-pencil.svg") no-repeat left center;
                        @include color-link($val);
                    }
                    .save-field{
                        background: url("../img/#{$key}/icon-save.svg") no-repeat left center;
                        @include color-link($val);
                    }
                }
            }
            &.error404-page{
                h1{
                    background: url("../img/#{$key}/error404-bg.svg") no-repeat center top;

                    @include bp(mobile){
                        background-size: contain;
                    }
                }
            }
            #atoz-nav {
                .ln-letters {
                    a {
                        &.all {
                            &.ln-disabled {
                                color: rgba($val,0.5);
                            }
                        }
                    }
                }
            }
            .contact-form{
                .professional-profile{
                    margin: 0 0 30px 0;
                    input[type="radio"]{
                        & + label{
                            color: $val;
                        }
                        &:checked + label{
                            color: rgba($val,0.3);
                        }
                    }
                }
                .main-form{
                    input[type="checkbox"], input[type="radio"]{
                        & + label{
                            a{
                                @include color-link($val);
                            }
                        }
                    }
                }
            }
            .news-list {
                a {
                    color: $val;
                }
                .read-more{
                    color: $val;
                }
            }
            .news-content {
                a {
                    color: $val;
                }
            }
            .lopd-block{
                a{
                    @include color-link($val);
                }
            }
            .owl-carousel {
                &.main-slide {
                    .goleft{
                        background: url("../img/#{$key}/arrow-slide-left.svg") no-repeat center center;
                    }
                    .goright{
                        background: url("../img/#{$key}/arrow-slide-right.svg") no-repeat center center;
                    }
                }
            }
            .book-resources{

                .button_download{
                    background-position: 10px center;
                    background-size: 10px;
                }
            }
            .custom-resources-subcategories{
                li{
                    a{
                        background: $val;
                        @include color-link(#fff);

                        &:hover{
                            background: darken($val,10%);
                        }
                    }
                }
            }
        }
    }
}

/* ===> SECONDARY COLORS*/
$secondarycolors: (
        planetalector: $color-planetalector-secondary,
        grup62: $color-grup62-secondary
);

@each $key, $val in $secondarycolors {
    body{
        &.skin-#{$key}{
            .home-featured, .educational-collection-detail, .book-detail{
                background: $val;
                padding-bottom: 30px;
            }
            .main-footer{
                border-top: 8px solid $val;
            }
            .header-desktop {

                .top-actions {
                    border-color: $val;
                }
                .bottom-actions {
                    border-bottom: 3px solid $val;
                }
            }
            .search-box {
                input[type="search"] {
                    background: $val;
                    border: 1px solid darken($val,10%);
                }
            }
            .sections-menu{
                .first-level{
                    li{
                        @include bp(tablet){
                            border-bottom: 2px solid $val;
                        }
                        &:last-child{
                            border: none;
                        }
                    }
                }
                @include bp(tablet){
                    .second-level{
                        li{
                            border-bottom: 2px solid $val;
                            padding-top: 7px;

                            &:last-child{
                                border: none;
                            }
                        }
                    }
                    .third-level{
                        li{
                            border-bottom: none;
                            padding-top: 0;
                        }
                    }
                }
            }
            .mobile-menu{
                .main-menu{
                    background: $val;
                }
            }
            .educational-collections-menu {

                .educational-collections-wrapper {

                    .aside-actions {
                        border-left: 6px solid $val;
                        padding: 0 0 0 20px;
                    }
                }
            }
            .secondary-footer{
                border-top: 6px solid $val;
            }
            .book-summary{
                .book-educational-collection{
                    border-top: 7px solid $val;
                }
            }
            .featured-author-photo{
                border: 12px solid $val;
            }

            .school-ranking {
                .school-ranking-list {
                    li {
                        &.active {
                            background: $val;
                        }
                    }
                }
            }
            .book-information{
                .book-classification{
                    border-color: $val;
                }
            }
            .book-sheet-tags, .book-sheet-features, .book-sheet-features li{
                border-color: $val;
            }
            .main-modal{
                border-bottom: 7px solid $val;
            }
            .purchase-options-list {
                border-top: 8px solid $val;

                li {
                    border-bottom: 2px solid $val;
                }
            }
            .book-sheet-actions{
                &.grouped-actions{
                    background: $val;
                }
            }
            textarea, input[type="text"], input[type="email"], input[type="password"], input[type="tel"], input[type="search"], input[type="number"] {
                border: 1px solid darken($val,5%);
                background: $val;

                &:focus{
                    background: lighten($val,2%);
                }
            }
            .book-detail-video-links-list{
                a{
                    background: $val;
                }
            }
            .header.sticky .educational-collections-menu{
                border-bottom: 7px solid $val;
            }
            .search-popup-box{
                border-bottom: 7px solid $val;

                &:before{
                    border-bottom: 7px solid $val;
                }
            }
            .search-results{
                background: $val;
                padding: 60px 0 80px 0;
            }
            .style-select{
                background: url("../img/commons/arrow-down-select.svg") no-repeat right 10px center $val;
                border: 1px solid darken($val,5%);
            }
            .page-header{
                border-bottom: 7px solid $val;
            }
            &.catalog-page, &.search-results-page, &.ranking-page, &.libraries-page, &.thematiclibrary-page, &.userprofile-page, &.faqs-page, &.downloadcatalog-page, &.error404-page, &.book-detail-page, &.aboutus-page, &.news-page{

                .page-header{
                    border-bottom: none;
                }
            }
            .search-view-options{
                border-top: 7px solid $val;
            }
            .breadcrumb{
                .current-page{
                    color: darken($val, 10%);
                }
            }
            .pager{
                .page-list{
                    a{
                        @include color-link(darken($val,10%));
                    }
                }
            }
            .bottom-pager{
                border-top: 7px solid $val;
            }
            .modal-searchfilters {
                .main-modal-header {
                    h1 {
                        border-bottom: 2px solid $val;
                    }
                }
            }
            .show-filter {
                @include bp(tablet) {
                    border-bottom: 2px solid $val;
                }
            }
            .search-filters{
                .second-level{
                    @include bp(tablet){
                        border-bottom: 2px solid $val;
                    }
                }
            }
            .modal-searchfilters{
                .main-modal-footer{
                    background: $val;
                }
            }
            .modal-access{
                .access-options{
                    background: $val;
                }
            }
            .modal-access{
                .access-options {
                    .login-option {
                        input[type="email"]{
                            background: url("../img/#{$key}/icon-user.svg") no-repeat right 15px center $val;
                            background-size: 16px;
                        }
                        input[type="password"]{
                            background: url("../img/#{$key}/icon-padlock.svg") no-repeat right 15px center $val;
                        }
                    }
                }
            }
            .modal-forgotpassword{
                input[type="email"]{
                    background: url("../img/#{$key}/icon-user.svg") no-repeat right 15px center $val;
                    background-size: 16px;
                }
            }
            .register-form{
                .professional-profile-options{
                    background: $val;

                    input[type="radio"]{
                        & + label{
                            background: lighten($val,20%);
                        }
                    }
                }
                .legal-note{
                    background: $val;
                    border: 1px solid darken($val,7%);
                }
            }
            .ranking-top{
                background: url("../img/#{$key}/ranking-bg.svg") no-repeat center center $val;
                background-size: contain;
            }
            #atoz-nav {
                .ln-letters {
                    a {
                        &.all{
                            //@include color-link(darken($val,10%));
                            background: $val;

                            &.ln-disabled{
                                background: none;
                            }
                        }
                        &.ln-enabled{
                            background: $val;

                            /*&.all{
                                background: none;
                                border-bottom: 1px solid $val;
                            }*/
                        }
                        &:hover{
                            background: $val;
                        }
                    }
                }
            }
            .trigger-letters{
                background: url("../img/commons/arrow-down-select.svg") no-repeat right 10px center $val;
                border: 1px solid darken($val,20%);
            }
            .author-detail{
                h1{
                    border-bottom: 6px solid $val;
                }
                .author-book-list, .author-bibliography{
                    border-top: 6px solid $val;
                }
                .author-bibliography{
                    a{
                        border-bottom: 6px solid $val;
                    }
                }
                .author-preview{
                    h1{
                        border: none;
                    }
                }
            }
            .author-social-list{
                background: $val;
            }
            .libraries-page-list{
                background: $val;
            }
            .libraries-list-selection{
                li{
                    border-bottom: 2px solid $val;
                }
            }
            .trigger-libraries-list{
                background: url("../img/commons/arrow-down-select.svg") no-repeat right 20px center $val;

                &.active{
                    background: url("../img/commons/arrow-up-select.svg") no-repeat right 20px center $val;
                }
            }
            .libraries-dropdown-list{
                border-bottom: 6px solid $val;
            }
            .library-educational-collection{
                border-top: 6px solid $val;
            }
            .library-book-list{
                background: $val;
            }
            .modal-welcome{
                .advantage-register-list{
                    border-bottom: 6px solid $val;
                }
            }
            .search-flyout{
                a {
                    border-bottom: 2px solid $val;
                }
            }
            .search-results-cta{
                border-top: 6px solid $val;
            }
            .user-card{
                .user-photo-preview{
                    border: 12px solid $val;
                }
            }
            .profile-sections-content-wrapper{
                background: $val;
            }
            .profile-sections{
                .section-selection-input:checked + .section-selection-label {
                    opacity: 1;
                    background: $val;
                }
            }
            .profile-sections {
                .profile-form {
                    input[type="text"],input[type="email"], input[type="password"]{
                        background: none;
                        border: none;

                        &.editable{
                            border: 1px solid $val;
                            background: $val;
                        }
                    }
                }
                .change-password{
                    input[type="password"]{
                        border: 1px solid $val;
                        background: $val;
                    }
                }
            }
            .faq-title{
                background: url("../img/commons/arrow-down-select.svg") no-repeat right 20px center $val;
                border: 1px solid darken($val,7%);

                &.active{
                    background: url("../img/commons/arrow-up-select.svg") no-repeat right 20px center $val;
                }
            }
            .faq-content{
                border-left: 1px solid darken($val,7%);
                border-right: 1px solid darken($val,7%);
                border-bottom: 1px solid darken($val,7%);
            }
            .catalog-list-wrapper{
                background: url("../img/#{$key}/ranking-bg.svg") no-repeat center center $val;
                background-size: contain;
            }
            .module-featured-text{
                background: $val;
            }
            .contact-form{
                .professional-profile-options{
                    background: $val;

                    input[type="radio"]{
                        & + label{
                            background: lighten($val,20%);
                        }
                    }
                }
            }
            .featured-news, .modal-news-detail{
                border-bottom: 6px solid $val;

                .news-features{
                    border-bottom: 6px solid $val;
                }
            }
        }
    }
}



/*
    ////// COLLECTIONS MENU
*/

$educational-collections: (
        infantil: $color-infantil,
        primaria: $color-primaria,
        secundaria: $color-secundaria,
        bachillerato: $color-bachillerato,
        decimo: $color-decimo,
        pequenoastronauta: $color-pequenoastronauta,
        planetaamarillo: $color-planetaamarillo,
        planetarojo: $color-planetarojo,
        planetaverde: $color-planetaverde,
        planetaazul: $color-planetaazul,
        planetatierra: $color-planetatierra,
        granconstelacion: $color-granconstelacion
);

@each $key, $val in $educational-collections {
    .educational-collections-menu{
        .educational-collection-#{$key}{
            .educational-collection-name{
                background: url("../img/educational-collections/icon-collection-#{$key}.svg") no-repeat 10px center;
                background-size: 30px;

                a{
                    @include color-link($val);
                }
            }

            &:hover{
                .educational-collection-name{
                    background: url("../img/educational-collections/icon-collection-#{$key}.svg") no-repeat 10px center $color-super-light-gray;
                    background-size: 30px;
                }
            }

            @include bp(tablet){
                .educational-collection-name{
                    background: url("../img/educational-collections/icon-collection-#{$key}.svg") no-repeat 10px center;
                    background-size: 30px;
                }
            }

            .third-level{
                color: $val;

                li{
                    background: url("../img/educational-collections/arrow-menu-collection-#{$key}.svg") no-repeat left 8px;

                    a{
                        @include color-link($val);
                        border-bottom: 1px solid transparent;

                        &:hover{
                            border-bottom: 1px solid $val;
                        }
                    }
                }
            }
        }
    }
    .book-summary, .library-summary, .news-content {
        .educational-collection-#{$key} {
            .educational-collection-link{
                &:before{
                    content: '';
                    background: url("../img/educational-collections/icon-collection-#{$key}.svg") no-repeat center center;
                    background-size: 30px;
                }
            }
        }
    }
    .educational-collection-icon-list{
        .educational-collection-#{$key}{
            background: url("../img/educational-collections/icon-collection-#{$key}.svg") no-repeat center center;
            background-size: 30px;
            width: 30px;
            height: 30px;
        }
    }
    .educational-collection-banner{

        &.educational-collection-#{$key}{
            background: url("../img/educational-collections/collection-#{$key}-bg.jpg") no-repeat center center;
            background-size: cover;

            li{
                background: url("../img/educational-collections/arrow-menu-collection-#{$key}.svg") no-repeat left 8px;

                a{
                    @include color-link($val);
                    border-bottom: 1px solid transparent;

                    &:hover{
                        border-bottom: 1px solid $val;
                    }
                }
            }

            &:after{
                content: '';
                background: url("../img/educational-collections/icon-collection-#{$key}.svg") no-repeat center center;
            }
        }
        .narrow &{
            &.educational-collection-#{$key}{
                h3{
                    color: $val;
                }
            }
        }
    }
    .educational-collection-detail{
        &.educational-collection-#{$key}{
            .educational-collection-title{
                background: url("../img/educational-collections/icon-collection-#{$key}.svg") no-repeat left center;
                background-size: 44px;

                @include bp(tablet){
                    background: url("../img/educational-collections/icon-collection-#{$key}.svg") no-repeat center top;
                    background-size: 36px;
                }
            }
            .button_primary{
                background: $val;

                &:hover, &:active{
                    background: darken($val,10%);
                }
            }
            .school-ranking {
                .school-ranking-list {
                    li {
                        &:before{
                            background: $val;
                        }
                    }
                }
            }
        }
    }
    .book-information{
        .educational-collection-#{$key}{
            background: url("../img/educational-collections/icon-collection-#{$key}.svg") no-repeat left center;
            background-size: 24px;
        }
    }
    .ranking{
        &.educational-collection-#{$key}{
            .ranking-top, .ranking-bottom{
                .book-list {
                    .book-item {
                        &:after {
                            background: $val;
                        }
                    }
                }
            }
        }
    }
    .libraries-list-selection{
        li{
            &.libraries-#{$key}{
                a{
                    @include color-link($val);
                }
            }
        }
    }
}