/********************************************************************************* BOOKS */

/*  index

    ////// BOOK ITEM
    ////// FEATURED BOOKS
    ////// RECOMMENDED BOOKS
    ////// BOOK DETAIL
    ////// BOOK LIST VIEWS (ONE BELOW THE OTHER)
*/

/*
    ////// BOOK ITEM
*/
.book-list, .book-carousel{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 32px;

    animation: fadeIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-fill-mode: forwards;

    @include bp(desktop){
        grid-template-columns: repeat(4, 1fr);
    }
    @include bp(tablet){
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
    }
    @include bp(mobile){
        grid-template-columns: repeat(2, 1fr);
    }
}
.book-item{
    background: white;
    border-radius: 8px;
    @extend %main-transition;

    &:hover{
        background: rgba(white,0.5);

        .book-thumbnail{
            opacity: 0.5;
        }
    }
}
.book-thumbnail{
    margin: 0 0 10px 0;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    @extend %main-transition;

    a{
        display: block;
        height: 325px;

        img{
            max-height: 100%;
            width: 100%;
            //height: 100%;
            //object-fit: fill;
            object-position: 50% top;
            //object-fit: cover;


        }

        /*@include bp(tablet){
            height: 270px;
        }*/
        @include bp(mobile){
            //height: 215px;
            height: 300px;
        }
        @include bp(minimobile){
            height: 400px;
        }
    }
}
.book-summary{

    p{
        padding: 0 15px;
    }
    .book-title{
        @include font-size(1.5);
        font-weight: $bold;
        text-transform: uppercase;
        min-height: 60px;
        max-height: 65px;
        overflow-y: hidden;

        a{
            @include color-link($color-main);
        }
    }
    .book-summary-text{

    }
    .book-author{
        @include font-size(1.2);
        font-weight: $medium;
        color: $color-medium-gray;
        height: 37px;
        overflow: hidden;
        padding-bottom: 5px;

        a{
            @include color-link($color-medium-gray);
        }
    }
    .book-totalpages{
        font-style: italic;
        @include font-size(1.2);
        font-weight: $normal;
        color: $color-medium-gray;
        margin: -10px 0 10px 0;
    }
    .book-educational-collection{
        @include font-size(1.2);
        text-transform: uppercase;
        padding-top: 10px;
        padding-bottom: 10px;

        a{
            display: block;
        }
    }
}


/*
    ////// FEATURED BOOKS
*/
.featured-books{
    /*h2{
        @include font-size(2.3);
        line-height: 27px;
        line-height: 2.7rem;
        text-transform: uppercase;
        font-weight: $bold;

        @include bp(mobile){
            @include font-size(2);
        }
    }*/
}


/*
    ////// RECOMMENDED BOOKS
*/
.recommended-books{
    /*h2{
        @include font-size(2.3);
        line-height: 27px;
        line-height: 2.7rem;
        text-transform: uppercase;
        font-weight: $bold;
    }*/
    padding: 40px 0;

    @include bp(mobile){
        padding: 25px 0;

        h2{
            text-align: center;
            @include font-size(2);
        }
        .book-item{
            background: none;
        }
        .book-thumbnail{
            a{
                height: 340px;
            }
        }
        .book-summary{
            display: none;
        }
    }
}

/*
    ////// BOOK DETAIL
*/
.book-sheet{
    background: white;

    h2{
        @include font-size(1.8);
        font-weight: $bold;
        margin: 0 0 10px 0;
    }
    .goto-comments{
        margin: 0 0 30px 0;
        display: block;
    }
}
.book-sheet-image-and-actions{
    img{
        max-width: 100%;
    }

    @include bp(tablet){
        text-align: center;
    }
}
.book-sheet-image{
    margin: 0 0 30px 0;
}
.book-extra-images {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 30px 0;

    .extra-image {
        max-width: 33%;
        box-sizing: border-box;
        padding-right: 5px;
        width: calc(33% - 5px);
        position: relative;
        margin-bottom: 5px;
        margin-right: 5px;
    }
}
.book-information{
    display: grid;
    grid-template-columns: 1.5fr 3.5fr;
    grid-gap: 50px;
    padding: 0 0 80px 0;

    h1{
        @include font-size(3.6);
        font-weight: $bold;
        text-transform: uppercase;
        margin: 0;
    }
    .book-author{
        @include font-size(2.2);
        font-weight: $medium;
        color: $color-medium-gray;
        text-transform: uppercase;
    }
    .book-classification{
        margin: 0 0 30px 0;
        padding: 0 0 30px 0;
        border-bottom: 6px solid $color-main;
    }
    .educational-collection{
        display: inline-block;
        vertical-align: middle;
        @include font-size(1.4);
        font-weight: $medium;
        color: $color-medium-gray;
        text-transform: uppercase;
        margin: 0 20px 0 0;
        padding: 5px 0 5px 30px;
    }
    .book-theme{
        display: inline-block;
        vertical-align: middle;
        @include font-size(1.4);
        font-weight: $bold;
        margin: 0;
    }

    @include bp(tablet){
        grid-template-columns: 1fr;
    }
}
.book-sheet-card{
    @include bp(tablet){
        text-align: center;
    }
}
.book-sheet-downloads{
    margin-bottom: 30px;

    .button_download{
        display: inline-block;
        vertical-align: top;
        margin: 0 10px 10px 0;

        @include bp(mobile){
            display: block;
            margin: 0 0 20px 0;
        }
    }
}
.book-sheet-tags, .book-sheet-features{
    margin: 60px 0 0 0;
    padding: 40px 0 0 0;
    border-top: 6px solid $color-main;
}

.book-sheet-tags{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 80px;

    li{
        margin: 0 0 5px 0;
    }
    a{
        &:hover{
            text-decoration: underline;
        }
    }

    @include bp(tablet){
        //grid-template-columns: 1fr;
        grid-gap: 10px;
    }
}

.book-sheet-features{
    ul{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0;

        @include bp(tablet){
            grid-template-columns: 1fr;
        }
    }
    li{
        @include font-size(1.4);
        margin: 0 0 5px 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0;
        border-bottom: 6px solid $color-main;
        padding: 10px 0;
    }
    .feature-label{
        font-weight: $normal;
    }
    .feature-value{
        font-weight: $bold;
    }
}
.purchase-options-list{
    padding: 20px 0 0 0;

    li{
        padding: 0 0 20px 0;
        margin: 0 0 20px 0;
        @include flex-container;
        align-items: center;
    }
    .purchase-logo{

        img{
            max-width: 150px;
        }
    }
    .button_primary{
        @include font-size(1.4);
        padding: 5px 30px;
    }
}
.read-first-chapter, .trigger-purchase-options, .trigger-purchase-options-background, .read-lecturanda {
    @extend .button_outline;
    @include font-size(1.4);
    padding: 15px 60px;
    display: block;
    width: calc(100% - 20px);
    margin: 0 10px;

    @include bp(tablet){
        padding: 15px 15px 15px 80px;
        text-align: left;
    }
}
.book-sheet-actions{
    margin: 0 0 10px 0;

    &.grouped-actions{
        padding: 10px;
        margin-top: 30px;
    }
    a, button{
        &:hover{
            opacity: 0.3;
        }
    }
}
.digital-book{
    margin: 0 0 10px 0;
    @include flex-container;
    height: auto;
    padding: 0 10px;

    .link-ebook, .link-ebook-background {
        @extend .button_outline;
        @include font-size(1.4);
        padding: 15px 20px 15px 30px;
        display: block;
        width: 100%;

        @include bp(tablet){
            padding: 15px 15px 15px 80px;
            text-align: left;
        }
    }
    .link-audiolibro, .link-audiolibro-background {
        @extend .button_outline;
        @include font-size(1.4);
        padding: 15px 20px 15px 45px;
        display: block;
        width: 100%;

        @include bp(tablet){
            padding: 15px 15px 15px 80px;
            text-align: left;
        }
    }

    li{
        @include flex-fixed-element(48%);
        margin: 0;

        &:only-child{
            @include flex-fixed-element(100%);

            .link-ebook, .link-ebook-background {
                padding: 15px 20px 15px 30px;

                @include bp(tablet){
                    padding: 15px 15px 15px 80px;
                    text-align: left;
                }
            }
            .link-audiolibro, .link-audiolibro-background {
                padding: 15px 20px 15px 40px;

                @include bp(tablet){
                    padding: 15px 15px 15px 80px;
                    text-align: left;
                }
            }
        }
        @include bp(desktop){
            @include flex-fixed-element(100%);
            margin: 0 0 10px 0;
        }
    }
}
.add-to-favs, .mark-as-read{
    @extend .button_outline;
    @include font-size(1.4);
    padding: 15px 60px;
    display: block;
    width: 100%;
    border: none;

    @include bp(tablet){
        padding: 15px 15px 15px 80px;
        text-align: left;
    }
}
.extra-information{
    //background: white;
    padding: 80px 0;

    /*h2{
        @include font-size(2.3);
        line-height: 27px;
        line-height: 2.7rem;
        text-transform: uppercase;
        font-weight: $bold;
    }*/
}
.extra-services{
    background: white;
    padding: 80px 0;
}
.trigger-author-visit, .trigger-book-sample{
    @include font-size(1.4);
    padding: 20px 20px 20px 80px;
    margin: 0 50px 0 0;

    @include bp(mobile){
        display: block;
        margin: 0 0 20px 0;
        text-align: left;
        padding: 10px 20px 10px 80px;
    }
}
.book-detail-audiovisual{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 30px;
    grid-template-areas: "video video video cover links";

    .book-detail-video{
        grid-area: video;

        .video-wrap{
            margin-bottom: 20px;
        }
    }
    .book-detail-cover{
        grid-area: cover;
    }
    .book-detail-video-links{
        grid-area: links;

        h3{
            @include font-size(1.8);
            text-transform: uppercase;
            font-weight: $bold;
        }
    }
    .book-detail-video-links-list{
        text-align: center;

        li{
            margin: 0 0 20px 0;
        }
        a{
            display: block;
            padding: 20px 30px;

            @include bp(tablet){
                padding: 20px 10px;
            }
        }
    }

    @include bp(tablet){
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: "video video video"
        "cover cover links";
        grid-gap: 10px;
    }
}

.book-detail-comments{
    padding: 80px 0;

    /*h2{
        @include font-size(2.3);
        line-height: 27px;
        line-height: 2.7rem;
        text-transform: uppercase;
        font-weight: $bold;
    }*/
    .book-detail-comments-actions{
        margin: 60px 0 0 0;
        text-align: center;
    }
}
#next-messages{
    padding: 10px 60px;
}
.comment-item{
    @include flex-container;
    background: white;
    padding: 30px 20px;
    margin: 0 0 30px 0;
    border-radius: 3px;

    .comment-user-photo{
        @include flex-fixed-element(60px);
        margin: 0 20px 0 0;
        border-radius: 100px;
        overflow: hidden;
        width: 60px;
        height: 60px;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .comment-text{
        @include flex-flexible-element;

        h3{
            @include font-size(1.8);
            font-weight: $bold;
            margin: 0;
        }
        p{
            @include font-size(1.4);
            font-weight: $normal;
            color: $color-medium-gray;
        }
    }
}
.write-comment{
    background: white;
    padding: 80px 0;

    /*h2{
        @include font-size(2.3);
        line-height: 27px;
        line-height: 2.7rem;
        text-transform: uppercase;
        font-weight: $bold;
    }*/
    form{
        &:after {content: "."; display: block; height: 0; clear: both; visibility: hidden;}
    }
    textarea{
        margin: 20px 0 40px 0;
    }
    .button_primary{
        float: right;

    }
    @include bp(mobile){
        padding: 40px 0;

        textarea{
            margin: 20px 0;
            min-height: 200px;
        }

        .button_primary{
            float: none;
            width: 100%;
        }
    }
}
.book-detail-gallery{
    margin: 60px 0;

    &.owl-carousel{
        display: none;

        &.owl-loaded{
            opacity: 0;
            animation: fadeIn;
            animation-duration: 0.5s;
            animation-timing-function: ease-out;
            animation-delay: 0s;
            animation-fill-mode: forwards;
        }
    }
}
#contenidos-extra{
    scroll-margin-top: 150px;
}
.book-resources{
    max-width: $wrapper;
    margin: 30px auto;
    @include flex-container;
    height: auto;

    .button_download{
        padding: 7px 20px 7px 30px;
        @include font-size(1.2);
        margin: 0 0 0 10px;
        text-align: right;
    }

    @media screen and (max-width: $wrapper){
        padding: 0 10px;
    }
}
.book-resources-group{
    margin: 0 0 40px 0;
    @include flex-fixed-element(48%);

    h2{
        margin: 0 0 5px 0;
        font-weight: $bold;
        @include font-size(1.8);
    }

    @include bp(tablet){
        @include flex-fixed-element(100%);
        margin: 0 0 30px 0;
    }
}
.book-resources-list{
    li{
        border-bottom: 1px solid $color-medium-gray;
        margin: 0;
        padding: 5px 0;
        @include flex-container;
        height: auto;

        &:hover{
            background: $color-super-light-gray;
            font-weight: $bold;
        }
    }
    .resource-title{
        @include font-size(1.4);
    }
}


/*
    ////// BOOK LIST VIEW (ONE BELOW THE OTHER)
*/
.book-list-view{
    .book-list{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 40px;

        @include bp(tablet){
            grid-template-columns: 1fr;
        }
        @include bp(mobile){
            grid-template-columns: 1fr;
            grid-gap: 20px;
        }
    }
    .book-item{
        @include flex-container;
    }
    .book-title{
        min-height: 1px;
        max-height: none;
    }
    .book-thumbnail{
        @include flex-fixed-element(23%);
        margin: 0;

        @include bp(desktop){
            @include flex-fixed-element(25%);

            img{
                height: 100%;
            }
        }
        @include bp(tablet){
            a{
                height: 270px;
            }
        }
        @include bp(mobile){
            @include flex-fixed-element(33%);

            a{
                height: 200px;
            }
        }
    }
    .book-summary{
        @include flex-fixed-element(75%);
        padding: 30px 20px 20px 0;

        @include flex-container;
        flex-direction: column;

        @include bp(desktop){
            @include flex-fixed-element(73%);
        }
        @include bp(mobile){
            @include flex-fixed-element(63%);
            padding: 20px 10px 10px 0;

            p{
                padding: 0;
            }
        }
    }
    .book-author{
        height: auto;
    }
    .book-summary-text{
        @include bp(tablet){
            display: none;
        }
    }
}
