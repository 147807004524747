/********************************************************************************* MODALS */

/*  index

    ////// COMMONS
    ////// ACCESS MODAL
    ////// FORGET PASSWORD MODAL
    ////// AUTHOR VISIT MODAL
    ////// SEARCH FILTERS
    ////// WELCOME MODAL
    ////// CHANGE REGION MODAL
    ////// DELETE ACCOUNT
    ////// NEWS DETAIL
*/

/*
    ////// COMMONS
*/
.main-modal{
    display: none;
    //position: absolute;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: map-get($zindex, modals);
    background: rgba($color-main,0.9);
}
.main-modal-wrapper{
    background: white;
    @include flex-container;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    max-width: $wrapper;
    margin: 0 auto;
    padding: 20px 40px 40px 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

    @media screen and (min-width: 1500px){
        width: 70%;
    }

    @include bp(desktop){
        width: 100%;
        padding: 20px 20px 40px 20px;
        display: block;
        height: 100vh;
        overflow: scroll;
    }
    @include bp(mobile){
        padding: 20px 10px 40px 10px;
    }
}
.main-modal-header{
    text-align: right;
    padding: 15px 20px;
    width: 100%;
}
.main-modal-close{
    display: block;
    height: 30px;
    position: relative;
    z-index: 100000;
}
.main-modal-content{
    margin: 0 auto;
    padding: 300px 0 0 0;
    width: 100%;
    overflow: auto;
    max-height: 85vh;

    @supports ( display: flex ) {
        padding: 0 0 3px 0;
    }
    @media screen and (max-width: $wrapper){
        padding-left: 20px;
        padding-right: 20px;
    }

    h2{
        @include font-size(2.3);
        text-transform: uppercase;
        font-weight: $bold;
        margin: 0 0 10px 0;
    }
}
.main-modal-title{
    margin: 0 0 40px 0;
    text-align: center;
}
.main-modal-footer{
    text-align: center;
}


/*
    ////// ACCESS MODAL
*/
.modal-access{

    .main-modal-wrapper{
        max-width: 1000px;
        margin: 0 auto;
    }
    .access-options{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;

        .login-option{
            background: white;
            padding: 10px 50px;
        }
        .register-option{
            background: white;
            padding: 10px 50px;
        }
        @include bp(tablet){
            grid-template-columns: 1fr;

            .login-option{
                padding: 10px 10px 50px 10px;
            }
            .register-option{
                padding: 50px 10px 10px 10px;
            }
        }
    }
    .register-advantages{
        margin: 30px 0;

        li{
            @include font-size(1.4);
            margin: 0 0 20px 0;
            background: url("../img/commons/arrow-right.svg") no-repeat left 4px;
            padding-left: 15px;
        }
    }
    .forgotpassword{
        @include font-size(1.4);
        font-weight: $bold;
        margin: -20px 0 20px 0;

        a{
            display: block;

            &:hover{
                text-decoration: underline;
            }
        }
    }
}

/*
    ////// FORGOT PASSWORD MODAL
*/
.modal-forgotpassword{
    text-align: center;

    .main-modal-wrapper{
        max-width: 800px;
    }
    .main-modal-content{
        max-width: 500px;
    }
    .main-form{
        max-width: 320px;
        margin: 40px auto;

        label{
            text-align: left;
        }
    }
}


/*
    ////// AUTHOR VISIT MODAL
*/


/*
    ////// SEARCH FILTERS MODAL
*/
.modal-searchfilters{
    .main-modal-header{
        h1{
            text-align: left;
            @include font-size(1.8);
            margin-top: -25px;
            padding-bottom: 20px;
            margin-bottom: 0;
        }
    }
    .main-modal-wrapper{
        padding-bottom: 80px;

        @include bp(tablet){
            padding: 10px;
        }
    }
    .main-modal-footer{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 100000;
        padding: 10px;

        .button_primary, .button_outline{
            display: inline-block;
            padding: 12px 20px;
            @include font-size(1.3);
            margin: 0 5px;
        }
        .button_outline{
            background: white;
        }
        @include bp(tablet){
            position: relative;
        }
    }
}

/*
    ////// WELCOME MODAL
*/
.modal-welcome{
    text-align: center;

    .main-modal-content{
        max-width: 900px;

        p{
            text-align: left;
        }
    }
    .advantage-register-list{
        margin: 30px 0;
        padding: 0 0 30px 0;
        columns: 2;

        li{
            background: url("../img/commons/arrow-right.svg") no-repeat left 5px;
            padding: 0 0 0 20px;
            text-align: left;
            @include font-size(1.4);
        }
    }
}


/*
    ////// CHANGE REGION MODAL
*/
.modal-changeregion{
    text-align: center;

    .main-modal-wrapper{
        max-width: 800px;
    }
    .main-modal-content{
        max-width: 500px;
    }
    .button_primary{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .stay-here{
        font-weight: $bold;
        display: block;
    }
}

/*
    ////// DELETE ACCOUNT
*/
.modal-deleteaccount{
    text-align: center;

    .main-modal-wrapper{
        max-width: 800px;
    }
    .main-modal-content{
        max-width: 500px;
    }
    .button_primary, .button_outline{
        display: block;
        margin: 0 0 30px 0;
    }
}


/*
    ////// NEWS DETAIL
*/
.modal-news-detail{
    .news-content{
        @include flex-container;

        .news-files{
            @include flex-fixed-element(30%);
            max-width: 380px;

            .news-image{
                height: 380px;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                @include bp(mobile){
                    height: auto;
                }
            }

            @include bp(mobile){
                @include flex-fixed-element(100%);
                max-width: 100%;
                margin: 0 0 20px 0;
            }
        }
        .news-text{
            @include flex-fixed-element(70%);
            padding-left: 50px;

            @include bp(mobile){
                @include flex-fixed-element(100%);
                padding-left: 0;
            }
        }
        .educational-collection-link{
            color: $color-medium-gray;
            text-transform: uppercase;
            @include font-size(1.4);
        }
        h2{
            @include font-size(3);
            font-weight: $bold;

            @include bp(mobile){
                @include font-size(2.6);
            }
        }
        .news-cta{
            display: block;
            margin: 30px 0 0 0;

            .button_download{
                display: block;
                text-align: left;
                margin: 0 0 10px 0;
            }
        }
    }
    .news-features{
        padding: 0 0 10px 0;
        margin: 0 0 30px 0;
        @include flex-container;
        height: auto;
    }
    .news-date{
        font-weight: $bold;
        color: $color-main;
    }
    .news-summary{
        .long-text{
            display: block;
        }
        .short-text{
            display: none;
        }
    }
}

.modal-purchase-options {
    .main-modal-wrapper {
        max-width: 500px;

        .main-modal-title {
            margin: 0px;
            text-align: left;
            text-transform: uppercase;
        }
    }
}