/*INDEX THEME
	
	- BASE
    - LAYOUT
    - COMPONENTS
	- PAGES
    - PLUGINS 
	- MEDIAQUERIES
    - OLDIES

************************************************************************************/

/* 
=======================
PROJECT STYLES
======================= 
*/

/*BASE*/
@import 'base/colors';
@import 'base/variables';
@import 'base/mixins';
@import 'base/typography';
@import 'base/animations';

/*LAYOUT*/
@import 'layout/grid';

/*COMPONENTS*/
@import 'components/commons';
@import 'components/header';
@import 'components/footer';
@import 'components/tables';
@import 'components/forms';
@import 'components/navigation';
@import 'components/buttons';
@import 'components/modals';
@import 'components/pager';

/*PAGES*/
@import 'pages/home';
@import 'pages/books';
@import 'pages/educationalcollections';
@import 'pages/authors';
@import 'pages/libraries';
@import 'pages/search-results';
@import 'pages/catalog';
@import 'pages/register';
@import 'pages/ranking';
@import 'pages/userprofile';
@import 'pages/faqs';
@import 'pages/errors';
@import 'pages/about-us';
@import 'pages/contact';
@import 'pages/news';
@import 'pages/texts';
@import 'pages/resources';
@import 'pages/coleccion-profesor';

/*PLUGINS*/
@import 'plugins/owlcarousel/owlcarousel';
@import 'plugins/featherlight/featherlight.gallery';
@import 'plugins/listnav/listnav';

/*SKINS*/
@import 'skins/skins';

/*MEDIAQUERIES*/
@import 'mediaqueries/tweakpoints';

/*OLDIES*/
@import 'oldies/modernizr';
@import 'oldies/hacks';

/******* IF edition out of SCSS System is needed, please use "auxiliar.css" file in /css directory **************/

/* 
=======================
PROJECT STYLES
======================= 
*/







