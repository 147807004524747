/********************************************************************************* COLORS */

/*  index

    ////// ESSENTIALS
    ////// CUSTOM
    ////// BUTTONS
    ////// TABLES
*/

/*
    ////// ESSENTIALS
*/
$color-main: 					#4A4F56;
$color-secondary: 				#004FA3;
$color-green:                   #63B40A;
$color-red: 					#EA3D51;
$color-dark-gray:               #565656;
$color-medium-gray:             #7B828B;
$color-gray:                    #989898;
$color-light-gray:              #E2E2E2;
$color-super-light-gray:        #F6F6F6;

/*
    ////// CUSTOM
*/
$color-planetalector:           #004FA3;
$color-planetalector-secondary: #F1F7FA;
$color-grup62:                  #BB232A;
$color-grup62-secondary:        #F5F5F7;

/*Collections ES*/
$color-infantil:                #C2432A;
$color-primaria:                #87BE4B;
$color-secundaria:              #2C9EC6;
$color-bachillerato:            #7451C9;

/*Collections LATAM*/
$color-pequenoastronauta:       #966B9D;
$color-planetaamarillo:         #FFA800;
$color-planetarojo:             #EA3D51;
$color-planetaverde:            #1DBB90;
$color-planetaazul:             #20AACE;
$color-planetatierra:           #CE4A1A;
$color-granconstelacion:        #939AA4;
$color-decimo:                  #7451C9;


/*
    ////// BUTTONS
*/
$color-main-button:             #004FA3;
$color-main-button-text:        white;
$color-main-button-text-hover:  white;

/*
    ////// TABLES
*/
$color-table-header: 			#FBFBFB;