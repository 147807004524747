/********************************************************************************* USER PROFILE */

/*  index

    ////// COMMONS
    ////// CHANGE PHOTO
    ////// FAVORITES
    ////// NEWSLETTER
    ////// PROFILE
*/


/*
    ////// COMMONS
*/
.profile-sections{
    text-align: center;

    .profile-section-content {
        display: none;
        padding: 60px 0;
        text-align: left;

        .profile-section-title{
            @include font-size(1.8);
            font-weight: $bold;
            margin-bottom: 30px;
        }
    }

    .section-selection-input {
        display: none;

        & + .section-selection-label {
            display: inline-block;
            padding: 15px 50px;
            text-align: center;
            opacity: 0.5;
            color: $color-main;
            @include font-size(1.4);
            font-weight: $bold;
            text-transform: uppercase;
            width: calc(#{$wrapper}/4);
            box-sizing: border-box;

            @media screen and (max-width: $wrapper){
                width: auto;
            }
            @include bp(tablet){
                padding: 15px 20px;
                @include font-size(1.2);
            }
            @include bp(mobile){
                padding: 15px 10px;
            }
            @include bp(minimobile){
                padding: 15px 5px;
            }
        }
        &:checked + .section-selection-label {
        }
    }

    #tab1:checked ~ .profile-sections-content-wrapper #content1,
    #tab2:checked ~ .profile-sections-content-wrapper #content2,
    #tab3:checked ~ .profile-sections-content-wrapper #content3,
    #tab4:checked ~ .profile-sections-content-wrapper #content4 {
        display: block;
    }
}


/*
    ////// CHANGE PHOTO
*/
.user-card{
    @include flex-container;
    height: auto;
    justify-content: center;
    margin-bottom: 60px;

    .user-photo{
        position: relative;
        margin-right: 30px;
    }
    .user-photo-preview{
        width: 160px;
        height: 160px;
        overflow: hidden;
        border-radius: 100px;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .change-photo-form{
        position: absolute;
        top: 65%;
        right: 0;
        z-index: 1000;

        .change-photo-submit, #change-photo{
            display: none;
        }
        .change-photo-label{
            width: 40px;
            height: 40px;
            display: block;

            &:hover{
                cursor: pointer;
            }
        }
    }
    .user-name{
        h1{
            margin: 0;
            @include font-size(3);
            font-weight: $bold;
        }
        .user-email{
            margin: 0;
            @include font-size(1.6);
            font-weight: $medium;
            color: $color-medium-gray;
            @include color-link($color-medium-gray);
        }
        .logout{
            @include font-size(1.4);
            font-weight: $normal;
            padding: 5px 5px 5px 20px;
            margin-top: 20px;
            display: block;
        }
    }
}


/*
    ////// FAVORITES
*/
.profile-sections{
    .profile-book-list{
        @extend .book-list-view;

        .book-summary{
            position: relative;

            .book-educational-collection a{
                max-width: 50%;

                @include bp(tablet){
                    max-width: 80%;
                }
            }
        }

        .remove-user-book{
            color: $color-red;
            background: url("../img/commons/icon-bin.svg") no-repeat left center;
            padding: 5px 5px 5px 20px;
            text-align: left;
            @include font-size(1.4);
            border: none;
            position: absolute;
            bottom: 25px;
            right: 30px;
            z-index: 100;
            min-height: 30px;
            min-width: 30px;

            @include bp(tablet){
                background-position: center center;
                bottom: 20px;
                right: 20px;

                .remove-text{
                    display: none;
                }
            }
        }
        .book-summary-text{
            @include bp(tablet){
                display: none;
            }
        }
    }
}
#user-bookmark-books-pager, #user-read-books-pager{
    margin: 30px 0;

    .pager{
        justify-content: flex-end;
    }
}


/*
    ////// NEWSLETTER
*/
.profile-sections{
    .profile-newsletter-subscription{
        background: white;
        padding: 40px;
    }
    .main-form.profile-newsletter-subscription{
        fieldset{
            margin: 0;
        }
        .radiobutton-group{
            margin: 0 0 20px 0;

            input[type="radio"]+label{
                @include font-size(1.4);
                font-weight: $normal;
                text-transform: none;
            }
        }
        .button_primary{
            @include bp(mobile){
                width: 100%;
            }
        }
        input#subscribe-to-newsletter{
            & + label{
                text-transform: none;
                font-weight: $normal;
            }
        }
    }
    .newsletter-subject-list{
        margin: 20px 0;

        li{
            margin: 0;
        }
        p{
            @include font-size(1.4);
            font-weight: $bold;
            text-transform: uppercase;
        }
        label{
            @include font-size(1.4);
            font-weight: $normal;
            text-transform: none;
        }

        &.with-educational-collections{
            @include flex-container;
            height: auto;

            li{
                @include bp(tablet){
                    @include flex-fixed-element(48%);
                    margin: 0 0 20px 0;
                }
            }
        }
    }
}


/*
    ////// PROFILE
*/
.profile-sections {
    .profile-form {
        background: white;
        padding: 40px;

        .change-password-form{
            margin: 0 0 30px 0;
        }

        label{
            @include font-size(1.4);
            font-weight: $normal;
            text-transform: none;
            margin: 0;
        }

        input[type="text"],input[type="email"],input[type="password"]{
            pointer-events:none;
            @include font-size(1.4);
            padding: 10px 0;
            background: none;
            width: 270px;
            display: inline-block;
            font-weight: $bold;

            &.editable{
                pointer-events: auto;
                padding: 10px 20px;
            }
        }
        .edit-field, .edit-password, .save-field{
            @include font-size(1.4);
            padding: 5px 5px 5px 20px;
            margin: 0 0 0 10px;
        }
        .change-password{
            display: none;

            input{
                pointer-events: auto;
                padding: 10px 20px;
            }
        }
        .hint{
            @include font-size(1.2);
            color: $color-medium-gray;
        }
    }
}