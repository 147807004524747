/********************************************************************************* SEARCH RESULTS */

/*  index

    ////// PAGE LAYOUT
    ////// VIEW OPTIONS
    ////// FILTERS
*/


/*
    ////// PAGE LAYOUT
*/
.search-results-page{
    h1{
        text-transform: uppercase;
        @include font-size(2.3);
        font-weight: $bold;
        margin: 0 0 30px 0;

        @include bp(tablet){
            text-align: center;
        }
    }
    .page-header{
        border-bottom: none;
    }
}
.search-results{

    .center-wrapper{
        display: grid;
        grid-template-columns: 1fr 4fr;
        grid-gap: 30px;

        @include bp(tablet){
            grid-template-columns: 1fr;
        }
    }

    .results-grid-view{
        .book-list{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 32px;

            @include bp(tablet){
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 20px;
            }
            @include bp(mobile){
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
    .results-list-view{
        @extend .book-list-view;
    }
}


/*
    ////// VIEW OPTIONS
*/
.search-view-options{
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-gap: 32px;
    align-items: center;
    padding: 20px 0 0 0;

    select{
        @include font-size(1.3);
    }
    .pager{
        @include bp(tablet){
            justify-content: flex-start;
        }
        @include bp(minimobile){
            justify-content: space-between;
        }
    }
    .paginator{
        @include bp(minimobile){
            grid-column: 1 / span 3;
        }
    }

    @include bp(tablet){
        grid-template-columns: 4fr;
        grid-template-areas: "order order viewoptions viewoptions"
        "pager pager pager pager"
        ;

        .style-select{
            grid-area: order;
        }
        .trigger-views{
            grid-area: viewoptions;
        }
        .pager{
            grid-area: pager;
        }
    }
}
.trigger-view-grid, .trigger-view-list{
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 0 15px 0 0;
    border: none;
    background: none;
    @extend %main-transition;
}
.trigger-search-filters{
    display: none;
    width: 30px;
    height: 30px;
    margin: 0 15px 0 0;
    border: none;
    background: none;
    @extend %main-transition;

    @include bp(tablet){
        display: inline-block;
    }
}



/*
    ////// FILTERS
*/
.search-filters{

    li{
        margin: 0 0 20px 0;

        @include bp(tablet){
            margin: 0;
        }
    }

    .second-level{
        display: none;
        background: white;
        padding: 10px;

        li{
            margin: 0 0 5px 0;
        }

        input[type="checkbox"]{

            & + label{
                @include font-size(1.4);
                color: $color-medium-gray;
                text-transform: none;
                font-weight: $normal;
                margin: 0;
            }
            &:checked{

                & + label{
                    font-weight: $bold;
                }
            }
        }

        @include bp(tablet){
            border-bottom: 1px solid $color-main;
        }
    }

    .search-results &{
        @include bp(tablet){
            display: none;
        }
    }
}
.show-filter{
    background: url("../img/commons/arrow-down-select.svg") no-repeat right 10px center white;
    padding: 10px;
    display: block;
    margin: 0 0 10px 0;

    &.active{
        background: url("../img/commons/arrow-up-select.svg") no-repeat right 10px center white;
    }

    @include bp(tablet){
        margin: 0;
        padding: 20px 20px 10px 0;
    }
}