/********************************************************************************* ABOUT US */

/*  index

    ////// MODULE TEXT & PHOTO
    ////// MODULE FEATURED TEXT
    ////// MODULE TEXT LIST
*/

/*
    ////// MODULE TEXT & PHOTO
*/
.photo-and-text-module{
    @include flex-container;
    height: auto;
    max-width: $wrapper;
    margin: 0 auto 80px auto;

    h2{
        @include font-size(1.8);
        font-weight: $bold;
    }
    p{
        margin: 0 0 20px 0;
    }

    @include bp(mobile){
        padding: 0 20px;
    }
}
.photo-and-text-module_photo{
    @include flex-fixed-element(30%);

    img{
        width: 100%;
    }

    @include bp(mobile){
        @include flex-fixed-element(100%);
        margin: 0 0 30px 0;
    }
}
.photo-and-text-module_text{
    @include flex-fixed-element(70%);
    padding: 0 0 0 50px;

    @include bp(mobile){
        @include flex-fixed-element(100%);
        padding: 0;
    }
}


/*
    ////// MODULE FEATURED TEXT
*/
.module-featured-text{
    margin: 80px 0;
    padding: 60px;

    @include bp(mobile){
        padding: 40px 20px;
        text-align: center;
    }
}
.module-featured-text_text{
    max-width: $wrapper;
    margin: 0 auto;

    p{
        @include font-size(3);
        font-weight: $bold;
        font-style: italic;

        @include bp(mobile){
            @include font-size(2.6);
        }
    }
}


/*
    ////// MODULE TEXT LIST
*/
.module-text-list{
    max-width: $wrapper;
    margin: 0 auto 80px auto;

    h2{
        @include font-size(1.8);
        font-weight: $bold;
    }
    @include bp(mobile){
        padding: 0 20px;
    }
}
.module-text-list_list, .module-text-list ul{
    columns: 2;
    column-gap: 70px;
    padding: 0 0 0 20px;
    margin: 30px 0;

    li{
        list-style-type: disc;
        @include font-size(1.4);
    }

    @include bp(mobile){
        columns: 1;
    }
}