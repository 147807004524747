/********************************************************************************* FAQS */

/*  index

    ////// Resources
    ////// Subcategories
*/
.custom-resources{
    margin: 30px 0;
}

.custom-resources-category{
    margin: 0 0 70px 0;
}
.custom-resources-category-name{
    font-weight: $bold;
}

.custom-resources-category-image{
    width: 100%;
    margin: 0 0 20px 0;

    img{
        width: 100%;
    }
}

.custom-resources-list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;

    h3{
        @include font-size(1.8);
        padding-right: 30px;
        min-height: 60px;
    }

    @include bp(desktop){
        grid-template-columns: repeat(2, 1fr);
    }
    @include bp(mobile){
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
    }
}
.custom-resources_video{
    background: $color-super-light-gray;
    padding: 20px;
    border-radius: 5px;
    position: relative;

    &:before{
        content: url("../img/commons/icon-video.svg");
        position: absolute;
        z-index: 100;
        top: 20px;
        right: 20px;
    }
}
.custom-resources_audio{
    background: url("../img/commons/icon-music-big.svg") no-repeat center bottom 30px $color-super-light-gray;
    background-size: 90px;
    padding: 20px;
    position: relative;
    min-height: 250px;

    &:before{
        content: url("../img/commons/icon-music.svg");
        position: absolute;
        z-index: 100;
        top: 20px;
        right: 20px;
    }
    audio{
        width: 100%;
    }
    @include bp(mobile){
        background: $color-super-light-gray;
    }
}
.custom-resources_attachment{
    background: url("../img/commons/icon-pdf-big.svg") no-repeat center bottom 30px $color-super-light-gray;
    background-size: 80px;
    padding: 20px;
    position: relative;
    min-height: 250px;

    .button_download{
        display: block;
        padding: 7px 40px 7px 70px;
        text-align: left;
    }

    &:before{
        content: url("../img/commons/icon-pdf.svg");
        position: absolute;
        z-index: 100;
        top: 20px;
        right: 20px;
    }
    @include bp(mobile){
        background: $color-super-light-gray;
    }
}
.seemore-resources{
    margin: 40px 0;
    text-align: center;

    .button_plus{
        padding: 10px 40px;
        background: url("../img/commons/icon-plus.svg") no-repeat left 20px center $color-main;

        @include bp(mobile){
            background: url("../img/commons/icon-plus.svg") no-repeat left 20px center $color-main;
        }
    }
}


/*
////// Subcategories
 */
.custom-resources-subcategories{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    margin: 30px 0;

    li{
        margin: 0;

        a{
            text-align: center;
            padding: 20px;
            display: block;
            border-radius: 7px;
        }
    }

    @include bp(tablet){
        grid-template-columns: repeat(3, 1fr);
    }
    @include bp(mobile){
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }
    @include bp(minimobile){
        grid-template-columns: repeat(1, 1fr);
    }
}