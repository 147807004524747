/********************************************************************************* FAQS */

/*  index

    ////// ERROR 404
*/


/*
    ////// ERROR 404
*/
.error404-page{
    text-align: center;

    h1{
        padding-top: 300px;

        @include bp(mobile){
            padding-top: 210px;
        }
    }
}
.error-message{
    padding-bottom: 60px;

    h2{
        margin: 0;
        @include font-size(2.3);
        font-weight: $bold;
        text-transform: uppercase;
    }
    .button_primary{
        margin: 40px 0 0 0;
    }
}