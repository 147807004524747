/********************************************************************************* HEADER */

/*  index

    ////// HEADER
    ////// TOP ACTIONS
    ////// HEADER STICKY
*/

/*
    ////// HEADER
*/
.header{
    padding: 20px 0 0 0;

    @include bp(tablet){
        padding: 20px 0;
    }
}
.header-mobile, .header-desktop{
    display: none;
}

.header-desktop{
    @extend %main-transition;

    .top-actions{
        border-bottom: 6px solid $color-super-light-gray;

        .center-wrapper{
            display: grid;
            grid-template-columns: 1fr 3fr 1fr;
            align-items: center;
        }
    }
    .bottom-actions{
        padding: 15px 0;

        .center-wrapper{
            //display: grid;
            //grid-template-columns: 3.5fr 1.5fr;
            //align-items: center;
            //column-gap: 50px;
        }
    }
    .user-interaction{
        .search-sticky, .user-access-sticky{
            display: none;
        }
        .search-sticky{
            position: relative;
        }
        .trigger-search{
            display: block;
            width: 30px;
            height: 30px;
            border: none;
        }
        .user-access-sticky{
            .trigger-access{
                display: block;
                width: 30px;
                height: 30px;
                border: none;
            }
        }
    }
    .sticky &{
        .top-actions{
            .center-wrapper{
                grid-template-columns: 1fr 5fr 0.5fr;
            }
        }
    }
}
.search-popup-box{
    display: none;
    position: absolute;
    right: -20px;
    width: 300px;
    background: white;
    padding: 10px 20px 20px 20px;
    z-index: 1000;

    &:before{
        content: '';
        border-bottom: 7px solid $color-main;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        position: absolute;
        top: 5px;
        right: 30px;
    }
    input[type="search"]{
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            @include font-size(1.3);
            color: $color-medium-gray;
            font-style: italic;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            @include font-size(1.3);
            color: $color-medium-gray;
            font-style: italic;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            @include font-size(1.3);
            color: $color-medium-gray;
            font-style: italic;
        }
        &:-moz-placeholder { /* Firefox 18- */
            @include font-size(1.3);
            color: $color-medium-gray;
            font-style: italic;
        }
    }

    .search-mobile &{
        right: 0;
        width: 100%;

        &:before{
            right: 50px;
        }
    }
}
.header-mobile{
    .top-actions{
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        align-items: center;
    }
    .logo{
        text-align: center;
        position: relative;

        a{
            display: block;
        }

        img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            max-height: 30px;
        }
    }
    .show-mobile-menu{
        width: 100%;
        height: 30px;
        display: block;
        border: none;
        padding: 5px;
    }
    .trigger-group{
        text-align: center;
    }
    .search-mobile{
        display: inline-block;
        vertical-align: top;
    }
    .trigger-search{
        display: block;
        width: 30px;
        height: 30px;
        border: none;
        
        @include bp(minimobile){
            width: 27px;
        }
    }
    .trigger-access, .goto-profile{
        width: 30px;
        height: 30px;
        display: inline-block;
        vertical-align: top;
        border: none;

        @include bp(minimobile){
            width: 27px;
        }
    }
}


/*
    ////// TOP ACTIONS
*/
.user-access{
    text-align: right;

    .login{
        @include font-size(1.4);
        font-weight: $bold;
        padding: 5px 0 5px 30px;
    }
    span{
        display: inline-block;
        vertical-align: middle;
    }
    .user-profile-photo{
        width: 30px;
        height: 30px;
        border-radius: 100px;
        overflow: hidden;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .my-account, .signout{
        @include font-size(1.4);
        font-weight: $bold;
        margin: 0 0 0 7px;
    }
}

.search-box{
    position: relative;

    input[type="search"]{
        border-radius: 3px;
        padding: 8px 20px;
        width: 100%;
        margin-bottom: 5px;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            @include font-size(1.4);
        }
        &::-moz-placeholder { /* Firefox 19+ */
            @include font-size(1.4);
        }
        &:-ms-input-placeholder { /* IE 10+ */
            @include font-size(1.4);
        }
        &:-moz-placeholder { /* Firefox 18- */
            @include font-size(1.4);
        }
    }

    input[type="submit"]{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background: url("../img/commons/icon-search.svg") no-repeat center center;
        width: 40px;
        height: 40px;
    }
}

.search-flyout{
    position: absolute;
    z-index: 10000;
    background: white;
    width: 100%;
    padding: 20px;
    box-shadow: 0 5px 5px rgba($color-main,0.2);
    text-align: left;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 500px;

    .authors-search-results-list, .books-search-results-list{
        &:blank, &:empty, &:-moz-only-whitespace{
            display: none;
        }
    }
    a{
        display: block;
        padding: 15px 0 10px 0;
    }
    .search-results-thumbnail{
        display: inline-block;
        width: 50px;
        vertical-align: top;

        img{
            border-radius: 2px;
        }
    }
    .search-results-text{
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 55px);
    }
    p{
        @include font-size(1.4);
        font-weight: $medium;
        color: $color-medium-gray;
        margin: 0;

        &.search-result-title{
            font-weight: $bold;
            color: $color-main;
            text-transform: uppercase;
        }
    }
    .search-results-cta{
        padding: 20px 0;

        .button_primary{
            @include font-size(1.4);
        }
    }

    .search-sticky & {
        margin-left: -20px;
    }
}


/*
    ////// HEADER STICKY
*/
.header{
    &.sticky{
        background: white;

        .header-desktop{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            background: white;
            z-index: 1000;

            .bottom-actions{
                display: none;
            }
            .user-interaction{
                text-align: right;

                .search-sticky, .user-access-sticky{
                    display: inline-block;
                    vertical-align: top;
                }
                .user-access{
                    display: none;
                }
            }
            .goto-profile{
                width: 30px;
                height: 30px;
                display: inline-block;
                vertical-align: top;
                border: none;
            }
        }
        .search-box{
            display: none;
        }
    }
}