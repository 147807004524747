/********************************************************************************* CONTACT */

/*  index

    ////// COMMONS
*/

/*
    ////// COMMONS
*/
.contact-form{
    padding: 60px 0;

    fieldset{
        display: block;
        margin: 0 0 30px 0;
    }

    .main-form{
        input[type="checkbox"], input[type="radio"]{
            & + label{
                @include font-size(1.4);
                text-transform: none;
                color: $color-main;
                font-weight: $normal;
                margin: 0;

                a{
                    text-decoration: underline;
                }
            }
        }
    }
    .style-select{
        select{
            @include font-size(1.4);
            color: $color-medium-gray;
            padding: 15px 20px;
        }
    }
    .professional-profile{

        p{
            @include font-size(1.5);
            font-weight: $bold;
            text-transform: uppercase;

            @include bp(mobile){
                @include font-size(1.2);
            }
        }
        .professional-profile-options{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 30px;
            padding: 20px 30px;

            @include bp(tablet){
                grid-gap: 20px;
            }
            @include bp(mobile){
                grid-template-columns: repeat(2, 1fr);
                padding: 20px 10px;
            }
        }

        input[type="radio"]{
            opacity: 0;
            position: absolute;
            z-index: 1;

            & + label{
                padding: 20px 40px;
                @include font-size(1.2);
                font-weight: $normal;
                text-transform: uppercase;
                @extend %main-transition;
                width: 100%;
                box-sizing: border-box;
                text-align: center;
                margin: 0;
                position: relative;
                z-index: 10;

                @include bp(tablet){
                    padding: 20px;
                }
            }
        }
    }
    .contact-form_name, .contact-form_email, .contact-form_center, .contact-form_subject{
        @include bp(superdesktop){
            max-width: 50%;
        }
    }

    @include bp(mobile){
        padding: 30px 0;
    }
}