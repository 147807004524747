/********************************************************************************* FOOTER */

/*  index

    ////// MAIN FOOTER
    ////// SECONDARY FOOTER
*/


/*
    ////// MAIN FOOTER
*/
.footer{
    //margin-top: 80px;
}
.main-footer{
    padding: 10px 0;

    .center-wrapper{
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        grid-column-gap: 30px;
        align-items: center;
    }
    @include bp(tablet){

        .center-wrapper{
            grid-template-columns: 1fr;
            text-align: center;
        }
        .footer-menu{
            padding: 10px 0;
        }
        .main-menu{

            li{
                @include flex-fixed-element(100%);
            }
        }
    }
}
.region-selection{
    position: relative;
    text-align: right;

    @include bp(tablet){
        text-align: center;
    }
}
.region-selected{
    @include font-size(1.4);
    cursor: pointer;
}
.region-list{
    display: none;
    position: absolute;
    background: white;
    z-index: 10000;
    right: 0;
    border: 1px solid $color-super-light-gray;
    min-width: 100px;
    margin-top: 10px;
    box-shadow: 0 3px 2px rgba($color-main,0.2);

    li{
        @include font-size(1.4);
        text-align: center;
        margin: 0;
        border-bottom: 1px solid $color-super-light-gray;

        a{
            display: block;
            padding: 5px 10px;
            @extend %main-transition;

            &:hover{
                background: $color-super-light-gray;
            }
        }
    }
    @include bp(mobile){
        right: auto;
        left: 50%;
        transform: translateX(-50%);
    }
}


/*
    ////// SECONDARY FOOTER
*/
.secondary-footer{
    padding: 25px 0;

    .center-wrapper{
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-column-gap: 30px;
        align-items: center;

        @include bp(tablet){
            grid-template-columns: 1fr;
        }
    }
    .social-links{
        justify-content: flex-end;

        li{
            margin: 0 0 0 30px;
        }

        @include bp(tablet){
            justify-content: center;

            li{
                margin: 0 15px;
            }
        }
    }
}
.legal{
    p{
        color: $color-medium-gray;
        @include font-size(1.2);
        font-weight: $medium;
        text-transform: uppercase;

        a{
            @include color-link($color-medium-gray);
            margin: 0 10px;
            padding: 5px;
            display: inline-block;
            vertical-align: middle;

            &:hover, &:active{
                text-decoration: underline;
            }
        }
    }
    @include bp(tablet){
        text-align: center;
        
        a{
            display: block;
        }
    }
}