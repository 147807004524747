/********************************************************************************* CATALOG */

/*  index

    ////// PAGE LAYOUT
    ////// DOWNLOAD CATALOGS
*/


/*
    ////// PAGE LAYOUT
*/
.catalog-page{

}


/*
    ////// DOWNLOAD CATALOGS
*/
.catalog-list-wrapper{
    padding: 60px 0;
}
.catalog-list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 100px;

    .catalog-thumbnail{
        width: 100%;
        margin: 0 0 30px 0;

        img{
            width: 100%;
        }
    }
    .button_download{
        width: 100%;
    }

    @include bp(tablet){
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 70px;
    }

    @include bp(mobile){
        grid-template-columns: 1fr;
    }
}