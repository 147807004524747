/********************************************************************************* COLLECTIONS */

/*  index

    ////// COLLECTION LIST
    ////// COLLECTION DETAIL
*/

/*
    ////// COLLECTION LIST
*/
.book-educational-collections-banners{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;

    visibility: hidden;
    opacity: 0;
    animation: fadeIn;
    animation-duration: 0.4s;
    animation-timing-function: ease-out;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;

    @include bp(mobile){
        grid-template-columns: 1fr;
    }
}
.educational-collection-presentation{
    padding: 20px;

    h2{
        @include font-size(3.2);
        line-height: 37px;
        line-height: 3.7rem;
        font-weight: $bold;
        text-transform: uppercase;
        margin: 0;
    }
    p{
        @include font-size(1.8);
        line-height: 26px;
        line-height: 2.6rem;
        font-weight: $light;
        text-transform: uppercase;
        color: $color-medium-gray;
    }
    @include bp(mobile){
        text-align: center;
        padding: 20px 10px;

        h2{
            @include font-size(2.6);
            line-height: 30px;
            line-height: 3rem;
        }
        p{
            @include font-size(1.6);
            line-height: 22px;
            line-height: 2.2rem;
        }
    }
    .book-educational-collections-banners.bythree &{
        text-align: center;
        grid-column: 1 / span 3;

        @include bp(mobile){
            grid-column: auto;
        }
    }
}
.educational-collection-main-image{
    padding: 10px;
    background: url("../img/educational-collections/book-tower.png") no-repeat center bottom;
    background-size: contain;
    display: none;
    animation: fadeIn;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-fill-mode: forwards;

    img{
        max-width: 100%;
    }

    .book-educational-collections-banners.not-bythree &{
        display: block;

        @include bp(mobile){
            display: none;
        }
    }

    .narrow &{
        background-position-y: 20px;
    }
    @include bp(mobile){
        display: none;
    }
}
.educational-collection-icon-list{
    li{
        display: inline-block;
        vertical-align: top;
        margin: 0 10px 0 0;
    }
}
.educational-collection-banner{
    min-height: 320px;
    border-radius: 8px;
    padding: 35px 20px;
    position: relative;

    h3{
        @include font-size(2.2);
        font-weight: $bold;
        text-transform: uppercase;
        margin: 0;
    }
    .educational-collection-sections{
        margin: 10px 0;
    }
    li{
        @include font-size(1.5);
        font-weight: $medium;
        text-transform: uppercase;
        margin: 0;

        a{
            padding: 3px 5px 3px 10px;
            display: inline-block;
        }
    }
    &:after{
        position: absolute;
        left: 20px;
        bottom: 20px;
        border: 3px solid white;
        border-radius: 100px;
        width: 30px;
        height: 30px;
    }

    .narrow &{
        min-height: 170px;

        &:after{
            left: auto;
            right: 20px;
        }
    }
    .educational-collection-hint{
        @include font-size(1.2);
        color: $color-gray;
    }
    .goto-educational-collection{
        font-weight: $medium;
        background: url("../img/educational-collections/arrow-menu-collection-generic.svg") no-repeat left 6px;
        margin-top: 40px;

        a{
            padding: 3px 5px 3px 10px;

            &:hover{
                text-decoration: underline;
            }
        }
    }

    @include bp(tablet){
        min-height: 210px;
    }

    @include bp(mobile){
        min-height: 170px;

        &:after{
            left: auto;
            right: 20px;
        }
    }
}

.book-summary, .library-summary, .news-content{
    .book-educational-collection{
        margin: 0;
    }
    .educational-collection-name{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @extend %main-transition;
    }
    .educational-collection-link{
        position: relative;
        padding: 7px 10px 7px 50px;
        width: 100%;
        min-height: 50px;
        border-radius: 100px;

        &:before{
            position: absolute;
            width: 30px;
            height: 30px;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
        &:hover{
            background: $color-super-light-gray;
        }
    }
}

/*
    ////// COLLECTION DETAIL
*/
.educational-collection-detail{

    .owl-carousel{
        display: none;

        &.owl-loaded{
            opacity: 0;
            animation: fadeIn;
            animation-duration: 0.5s;
            animation-timing-function: ease-out;
            animation-delay: 0s;
            animation-fill-mode: forwards;
        }
    }
    .book-educational-collection{
        display: none;
    }

    .featured-books{
        .book-list{
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 30px;

            @include bp(tablet){
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 20px;
            }
            @include bp(mobile){
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}
.educational-collection-header{
    background: white;

    .button_primary{
        display: inline-block;
        vertical-align: middle;
        float: right;
        border-radius: 3px;

        @include bp(tablet){
            display: block;
            float: none;
            margin: 20px auto;
            max-width: 320px;
        }
        @include bp(mobile){
            width: 100%;
        }
    }
}
.educational-collection-title{
    padding: 0 0 0 55px;
    @include font-size(3.6);
    text-transform: uppercase;
    font-weight: $bold;
    display: inline-block;
    vertical-align: middle;
    margin: 0;

    @include bp(tablet){
        text-align: center;
        display: block;
        padding: 40px 0 0 0;
    }
}
.featured-books{
    padding: 80px 0;
}
.school-reading{
    background: white;
    padding: 80px 0;
    margin: 0 0 80px 0;

    h2{
        /*@include font-size(2.3);
        line-height: 27px;
        line-height: 2.7rem;
        text-transform: uppercase;
        font-weight: $bold;*/

        @include bp(tablet){
            text-align: center;
        }
        /*@include bp(mobile){
            @include font-size(2);
        }*/
    }
}
.school-ranking{
    @include flex-container;

    .school-ranking-list{
        @include flex-fixed-element(66%);
        columns: 2;
        break-inside: avoid;
        break-after: avoid;

        .school-ranking-book{
            display: none;

            @include bp(tablet){
                display: inline-block;
                vertical-align: top;
                width: 30%;
                min-width: 100px;
                padding-right: 10px;
            }
        }
        .school-ranking-info{
            display: block;

            @include bp(tablet){
                display: inline-block;
                vertical-align: top;
                width: 60%;
            }
        }
        li{
            margin: 0 0 30px 0;
            padding: 12px 80px;
            position: relative;
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;

            @include bp(tablet){
                padding: 12px 10px 12px 70px;
                margin: 0 0 10px 0;
            }

            &.active{
                border-radius: 100px;
                padding: 12px 80px;

                @include bp(tablet){
                    background: none;
                }
            }
            &:before{
                content: '';
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 100;
                width: 50px;
                height: 50px;
                border-radius: 100px;

                @include bp(tablet){
                    top: 20px;
                    transform: none;
                }
            }
            &:after{
                content: '1';
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1000;
                //background: url("../img/commons/imagotip-opacity.svg") no-repeat center center;
                width: 50px;
                height: 50px;
                text-align: center;
                color: white;
                @include font-size(2);
                font-weight: $bold;
                padding: 15px 0 0 0;

                @include bp(tablet){
                    top: 20px;
                    transform: none;
                    //background: url("../img/commons/imagotip-opacity.svg") no-repeat center 5px;
                    padding: 10px 0 0 0;
                }
            }
        }
        p{
            margin: 0;
        }

        @include bp(tablet){
            @include flex-fixed-element(100%);
            columns: 1;
        }
    }
    .ranking-book-name{
        @include font-size(1.5);
        line-height: 16px;
        line-height: 1.6rem;
        font-weight: $bold;
        text-transform: uppercase;

        a{
            @include color-link($color-main);
            display: block;
        }
    }
    .ranking-book-author{
        @include font-size(1.2);
        line-height: 18px;
        line-height: 1.8rem;
        font-weight: $medium;
        color: $color-medium-gray;

        a{
            @include color-link($color-medium-gray);
            display: block;
        }
    }
    .school-ranking-viewport{
        @include flex-fixed-element(33%);
        text-align: center;

        img{
            max-width: 100%;
            max-height: 100%;
        }

        .school-ranking-book{

            a{
                display: block;
            }
        }

        @include bp(tablet){
            display: none;
        }
    }
}